* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Noto Sans", sans-serif;
    font-size: 1rem;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    color: $corSecundaria;
    font-weight: 400;
}

a,
button {
    cursor: pointer;
    &:hover {
        text-decoration: none;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
}

.h-80 {
    height: 80% !important;
}

.bg-color-2 {
    background-color: $cor2;
}

.bg-color-8 {
    background-color: $cor8;
}

.bg-color-10 {
    background-color: $cor10;
}

.constrast-color-8,
.constrast-color-10 {
    color: white;
}

.txt-color-1 {
    color: $cor1;
}

.txt-color-2 {
    color: $cor2;
}

.txt-color-3 {
    color: $cor3;
}

.txt-color-4 {
    color: $cor4;
}

.txt-color-5 {
    color: $cor5;
}

.txt-color-6 {
    color: $cor6;
}

.txt-color-7 {
    color: $cor7;
}

.txt-color-8 {
    color: $cor8;
}

.txt-color-9 {
    color: $cor9;
}

.txt-color-10 {
    color: $cor10;
}

.txt-color-11 {
    color: $cor11;
}

.txt-color-12 {
    color: $cor12;
}

.txt-color-13 {
    color: $cor13;
}

.txt-color-17 {
    color: $cor17;
}

.txt-color-18 {
    color: $cor18;
}

.txt-color-22 {
    color: $cor22;
}

.txt-color-23 {
    color: #E54C4C;
}

.txt-color-24 {
    color: $cor24;
}

.txt-color-secondary {
    color: $corSecundaria !important;
}

.txt-color-gray {
    color: #8f8f92;
}

.txt-w-400 {
    font-weight: 400 !important;
}

.txt-w-600 {
    font-family: noto-sans;
    font-weight: 600 !important;
}

.txt-w-700 {
    font-weight: 700 !important;
}

.border-bottom-color3 {
    border-bottom: 1px solid $cor3;
}

.txt-border-bottom {
    margin-bottom: 5px;
    position: relative;
    &::after {
        content: "";
        height: 2px;
        width: 80%;
        background-color: $cor10;
        position: absolute;
        bottom: -5px;
        left: 0;
        border-radius: 5px;
    }
}

.dropdown-toggle-custom-perfil {
    padding: 0;
    @include breakpoint(md) {
        padding: 12px;
    }
    &:after {
        content: url("../images/seta-dropdown.svg");
        border: 0;
        vertical-align: 0;
    }
    &.invert-color {
        &:after {
            content: url("../images/seta-dropdown-white.svg");
        }
    }
}

h1 {
    font-size: 2.25rem;
}

.txt-size-12 {
    font-size: 0.75rem;
}

.txt-size-14 {
    font-size: 0.875rem !important;
}

.txt-size-14-5 {
    font-size: .90625rem !important;
}

.txt-size-15 {
    font-size: 0.938rem !important;
}

.txt-size-16 {
    font-size: 1rem !important;
}

.txt-size-20 {
    font-size: 1.25rem;
}

.txt-size-18 {
    font-size: 1.125rem;
}

.txt-size-22 {
    font-size: 1.375rem;
}

.txt-size-25 {
    font-size: 1.5625rem;
}

.txt-size-30 {
    font-size: 1.875rem;
}

.txt-truncate-2 {
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.w-75 {
    width: 90% !important;
    @include breakpoint(xl) {
        width: 75% !important;
    }
}

.txt-alert {
    color: #DF0000;
    width: 100%;
    display: flex;
    align-items: center;
    &::after {
        content: "!";
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 18px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #DF0000;
        margin-left: 10px;
    }
}

.bdcrumb {
    display: flex;
    flex-direction: row;
    color: #8F8F92;
    width: 100%;
    text-overflow: ellipsis;
    &:hover {
        color: #8F8F92;
    }
    .bdcrumb {
        width: auto;
    }
    @include breakpoint(md) {
        width: max-content;
    }
}

.bdcrumb2 {
    display: flex;
    flex-direction: row;
    color: $corSecundaria;
    width: max-content;
    &:hover {
        color: $corSecundaria;
    }
}

.col-lg-24 {
    @include breakpoint(lg) {
        flex: 0 0 calc(100% / 5);
        max-width: calc(100% / 5);
        position: relative;
        width: 100%;
        padding-right: 6px;
        padding-left: 6px;
    }
}

.bar-color {
    background-color: #1F8F3C;
}

@media print {
    .historico-atendimento {
        padding-top: 15px !important;
    }
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left !important;
    }
    .col-md-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .col-md-11 {
        max-width: 91.66666666666666%;
        flex: 0 0 91.66666666666666%;
    }
    .col-md-10 {
        max-width: 83.33333333333334%;
        flex: 0 0 83.33333333333334%;
    }
    .col-md-9 {
        max-width: 75%;
        flex: 0 0 75%;
    }
    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-md-7 {
        max-width: 58.333333333333336%;
        flex: 0 0 58.333333333333336%;
    }
    .col-md-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-md-5 {
        max-width: 41.66666666666667%;
        flex: 0 0 41.66666666666667%;
    }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }
    .col-md-2 {
        max-width: 16.666666666666664%;
        flex: 0 0 16.666666666666664%;
    }
    .col-md-1 {
        max-width: 8.333333333333332%;
        flex: 0 0 8.333333333333332%;
    }
    .mt-print-0 {
        margin-top: 0 !important;
    }
    .d-none {
        display: block !important;
    }
}

.w-md-40 {
    @include breakpoint(md) {
        width: 40% !important;
    }
}

.w-md-auto {
    @include breakpoint(md) {
        width: auto !important;
    }
}

.table-borderless td,
.table-borderless th {
    border: 0 !important;
}

.vertical-hr {
    border: none;
    border-right: 1px solid $cor3;
    height: 36px;
    width: 1px;
}

.add-cards-borders .border-color-10 {
    border: 1px solid $cor10 !important;
}

.add-cards-borders .border-color-8 {
    border: 1px solid $cor8 !important;
}

.add-cards-borders .border-color-2 {
    border: 1px solid $cor2 !important;
}

.add-cards-borders .border-color-20 {
    border: 1px solid $cor20 !important;
}

@media screen and (max-width: 767px) {
    .btn-xs-block {
        width: 100% !important;
    }
}