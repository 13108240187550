.tabela-relatorio {
    background: #FAFAFA;
    border: 1px solid #E1E1E1;
    padding: 0;
    break-inside: avoid;
}

.tabela-relatorio-linha-um {
    width: 100%;
}

.tabela-relatorio-linha-dois {
    background-color: #fff;
    width: 100%;
    border-top: 1px solid #E1E1E1;
    border-bottom: 1px solid #E1E1E1;
}

.tabela-relatorio-linha-tres {
    width: 100%;
}

