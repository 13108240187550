.geosalus-legenda {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;

    &::before {
        content: attr(data-label);
        width: 20px !important;
        height: 20px !important;
        border-radius: 50%;
        background-color: $cor3;
        position: absolute;
        left: 0;
        top: 2px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: bold;
    }

    &.municipio {
        &::before {
            content: "";
            background-image: url("/static/images/icone-casa-white.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;
        }
    }
}

.geosalus-local {
    position: relative;
    padding-left: 60px;
    margin-bottom: 40px;
    width: 100%;
    text-align: end;
    font-size: 25px;
    font-weight: bold;
    color: $cor8;

    &::before {
        content: attr(data-label);
        width: 48px !important;
        height: 50px !important;
        border-radius: 5px;
        background-color: $cor8;
        position: absolute;
        left: 0;
        top: 0px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
    }

    &.municipio {
        &::before {
            content: "";
            background-image: url("/static/images/icone-casa-white.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60%;
        }
    }

    &.bairro {
        &::before {
            content: "";
            background-image: url("/static/images/icone-local-white.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 35%;
        }
    }
}

.geosalus-indicador {
    display: flex;
    padding: 1px 13px;
    background-color: #EBF5FF;
    border: 1px solid #EBF5FF;
    border-radius: 5px;
    align-items: center;
    color: $cor10;
    margin-bottom: 18px;
    cursor: pointer;
    transition: all .5s;

    .geosalus-indicador__icon {
        font-size: 24px;
        margin-right: 8px;
    }

    .geosalus-indicador__titulo {
        font-family: noto-sans;
        font-weight: 600;
    }

    .geosalus-indicador__valor {
        font-size: 24px;
        font-weight: bold;
        margin-left: auto;
    }

    &.indicador-1.active {
        background-color: #F9EEFF;
        border-color: #A664B8;
        color: #A664B8;
    }

    &.indicador-2.active {
        background-color: #FFF1E3;
        border-color: #FFB673;
        color: #E07716;
    }

    &.indicador-3.active {
        background-color: #DAFFDE;
        border-color: #209E41;
        color: #209E41;
    }

    &.indicador-4.active {
        background-color: #FFFEE1;
        border-color: #F7CC40;
        color: #DDA900;
    }

    &.indicador-5.active {
        background-color: #FEEBEF;
        border-color: #F47CAC;
        color: #C46088;
    }
}

.geosalus-pagination__button {
    width: 38px;
    height: 38px;
    border: 1px solid $cor8;
    border-radius: 50%;
    background: url('/static/images/icone-seta-cor10.svg') no-repeat center;
    outline: none;

    &:focus, &:active {
        box-shadow: 0 0 0 0.2rem rgba(166, 100, 184, .25);
    }

    &.next {
        transform: rotate(180deg);
    }
}


.geosalus-legenda-cores {
    position: relative;

    div {
        display: inline-block;
        width: 26px;
        height: 15px;
        margin: 0 2px;
        transition: all .5s;
    }

    &::before, &::after {
        color: #4B4B4B;
        font-weight: 600;
        font-family: noto sans;
        font-size: 12px;
        position: absolute;
        top: -15px;
    }

    &::before {
        content: attr(min);
        left: 0;
        transform: translate(-50%, 0);
    }

    &::after {
        content: attr(max);
        right: 0;
        transform: translate(50%, 0);
    }
}