@media screen and (max-width: 600px) {
  .logo-custom {
    max-width: 40%;
  }

  .subheader {
    .first-col {
      border-right: none;
    }
  }

  .card-default {
    padding: 15px;
  }

  .alinhamento-btns-fichas {
    margin-top: 0px;
  }
}