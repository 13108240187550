.iniciar-caso {

    @include breakpoint(md) {
        .iniciar-caso-wrapper {
            background-color: white;
            border-radius: 5px;
            box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
            padding: 24px 32px;
        }

        .options-wrapper {
            width: 329px;
        } 

        .identificar-usuario {
            width: 410px;
        }
    }

    .buscar-plano-wrapper {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
        padding: 15px;

        .btn-add-plano {
            width: 100%;
        }

        @include breakpoint(md) {
            box-shadow: none;
            padding: 0;

            .btn-add-plano {
                width: auto;
            }
        }
    }

    .resumo-title {
        padding-bottom: 15px;
        border-bottom: 3px solid $cor8;
        
        @include breakpoint(md) {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .resumo-select {
        background-color: transparent;
        border: 1px solid $cor10 !important;
        color: $cor10;
        text-align: center;
        border-radius: 5px;

        &:hover {
            border: 1px solid $cor10;
        }

        &:focus {
            border: 1px solid $cor10;
            outline: 0 none;
            box-shadow: none;
        }
    }

    // .resumo-section {
    //     display: none;

    //     @include breakpoint(md) {
    //         display: block;
    //     }
    // }
    
}



.resumo-bottom-bar {
    position: fixed;
    bottom: 0;
    background: white;
    width: calc(100% - 72px);
    height: 70px;
    left: 72px;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.15);

    button {
        height: max-content;
    }

    @include breakpoint(md) {
        display: flex;
    }
}

.nav-pills {
    .nav-link.active {
        background-color: $cor10 !important;
        color: white !important;
        border: 1px solid $cor10 !important;
    }
}

.alinhamento-btns-fichas {
    margin-top: -28px;
}

