.momentPicker {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    .mmp-options-section {
        width: 38%;

        ul {
            list-style: none;
            width: 100%;

            li {
                width: 100%;
                cursor: pointer;
                text-align: center;
                padding: 8px;
                font-weight: bold;
                font-size: 0.938rem;
            }
        }
    }

    .mmp-calendar-section {
        width: 62%;
        padding: 5px 15px;

        .mmp-calendar {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .mmp-calendar-date {
                font-size: 0.875rem;
            }

            .mmp-btn-prev-month, .mmp-btn-next-month {
                font-size: 30px;
                width: 30px;
                height: 30px;
                cursor: pointer;
                text-align: center;
                line-height: 24px;
                border-radius: 50px;
                user-select: none;
            }

            .mmp-calendar-header, .mmp-calendar-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
            }

            .mmp-calendar-item {
                width: calc(100% / 7);
                text-align: center;
                font-size: 0.875rem;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                cursor: pointer;
                user-select: none;

                &.previous-month, &.next-month { 
                    color: #A6A6A6;
                }

                &.range {
                    background-color: #f6f6f6;

                    &.left {
                        border-radius: 50px 0px 0px 50px;
                    }

                    &.right {
                        border-radius: 0px 50px 50px 0px;
                    }
                }

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50px;
                }

                &::before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
            }

            .mmp-calendar-header-item {
                @extend .mmp-calendar-item;
                cursor: default;
                user-select: none;
                font-size: 0.75rem;
            }
        }
    }

    .mmp-actions-section {
        width: 100%;
        padding: 6px 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .mmp-action {
            cursor: pointer;
            user-select: none;
            border: none;
            background-color: transparent;
            outline: none;
        }
    
    }

    &.mmp-no-moments {
        .mmp-options-section {
            display: none;
        }

        .mmp-calendar-section {
            width: 100%;
        }

        @include breakpoint(md) {
            width: 290px;
        }
    }

    
    @include breakpoint(md) {
        width: 460px;
    }
}


// pink theme
.momentPicker.pink-theme {
    border: 1px solid $cor8;
    
    .mmp-options-section {
        border-right: 1px solid $cor8;
        color: $cor8;

        ul li:hover, ul li.selected {
            background-color: rgba(166, 100, 184, 0.2);
        }
    }

    .mmp-calendar-section .mmp-calendar {
        .mmp-calendar-item {
            &.today {
                color: $cor8 !important;
            }

            &.selected span{
                background-color: $cor8 !important;
                color: white !important;

                &:hover {
                    background-color: $cor8;
                }
            }

            span:hover {
                background-color: rgba(166, 100, 184, 0.2);
            }
        }

        .mmp-calendar-header-item {
            color: $cor8;
        }

        .mmp-calendar-date {
            color: $cor8;
        }

        .mmp-btn-prev-month, .mmp-btn-next-month{
            color: $cor8;
            &:hover {
                background-color: rgba(166, 100, 184, 0.2);
            }
        }
    }

    .mmp-actions-section {
        border-top: 1px solid $cor8;
        .mmp-action-apply {
            color: $cor8;
        }
        .mmp-action-cancel {
            color: #4B4B4B;
        }
    }
}


// blue theme
.momentPicker.blue-theme {
    border: none;
    width: 80vw;

    .mmp-options-section {
        color: $cor10;

        ul li:hover, ul li.selected {
            background-color: rgba(62, 134, 171, 0.2);
        }
    }

    .mmp-calendar-section {
        padding: 5px 8px;

        .mmp-calendar {
            .mmp-calendar-item {
                &.today {
                    color: $cor10 !important;
                }

                &.selected span{
                    background-color: $cor10 !important;
                    color: white !important;

                    &:hover {
                        background-color: $cor10;
                    }
                }

                span:hover {
                    background-color: rgba(62, 134, 171, 0.2);
                }
            }

            .mmp-calendar-header-item {
                color: $cor10;
            }

            .mmp-calendar-date {
                color: $cor3;
            }

            .mmp-btn-prev-month, .mmp-btn-next-month{
                color: $cor3;
                &:hover {
                    background-color: rgba(62, 134, 171, 0.2);
                }
            }
        }
    }

    .mmp-actions-section {
        justify-content: space-between;
        padding-bottom: 15px;

        .mmp-action-apply {
            width: 40%;
            color: white;
            background-color: $cor10;
            border: 1px solid $cor10;
            border-radius: 5px;
            padding: 6px 5px;
            font-size: 0.875rem;
        }
        .mmp-action-cancel {
            width: 40%;
            color: $cor10;
            background-color: white;
            border: 1px solid $cor10;
            border-radius: 5px;
            padding: 6px 5px;
            font-size: 0.875rem;
        }
    }

    @include breakpoint(md) {

        &.mmp-no-moments {
            width: 290px;
        }

        width: 460px;
    }
}