

  /* .nav-painel-indicadores {
    border-color: #BDBDBD;
    .nav-link {
      border-top: none;
      border-right: none;
      border-left: none;
      color: $cor13;
      padding-bottom: 20px;
      border-color: transparent;
    }

    .nav-link.active {
      position: relative;
      color: $cor10 !important;
      padding-bottom: 20px;
      font-weight: 700;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        border-bottom-color: transparent;
      }

      &:after {
        position: absolute;
        height: 5px;
        width: 60px;
        border-radius: 5px;
        background-color: $cor10;
        content: ' ';
        bottom: -3px;
        left: 0;
      }

    }

    .nav-link:focus,
    .nav-link:hover {
      border-color: transparent;
    }
  } */

  #ex5, #tabPainelIndicadores, #tabsVinculos {
    border: none;
    border-bottom: 1px solid #BDBDBD;
    flex-wrap: nowrap;

    .nav-item {
      white-space: unset;
    }

    .nav-link {
      color: #a6a6a6;
      letter-spacing: -0.035em;
      padding: 0;
      text-align: center;

      @include breakpoint(md) {
        text-align: left;
      }
      
      @include breakpoint(lg) {
        padding: 30px 50px 7px;
        font-weight: 600;
        letter-spacing: normal;
        padding: 0 16px;
      }
    }

    .nav-item.show .nav-link,
    .nav-link.active {
      color: $cor10;
      border: none;
      border-bottom: 3px solid $cor10;
      background-color: transparent;
      bottom: -15px;
    }

    @include breakpoint(md) {
      flex-wrap: wrap;

      .nav-item {
        &:not(:last-child) {
          .nav-link {
            margin-right: 25px;
          }
        }
      }
    }
  }


  
  #ex5-content, #tabContentSalaDeSituacao, #contentCentralDoUsuario, #tabContentPainelIndicadores {
    .card-artigos-uteis {
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      background-color: white;
      border-radius: 0.5rem;
    }

    .border-bottom-color-10 {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        background-color: #B0D7EC;
        width: 40px;
        height: 3px;
        left: 0px;
        bottom: -10px;
      }
    }

    .border-bottom-color-8 {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        background-color: #D2A3DF;
        width: 40px;
        height: 3px;
        left: 0px;
        bottom: -10px;
      }
    }

    p {
      margin-bottom: 30px;
    }
    .card {
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
  
      .card-body {
        padding-bottom: 0;
      }
      h4 {
        font-size: 2.875rem;
        line-height: 63px;
        text-align: right;
        letter-spacing: -0.03em;
        position: relative;
        margin-bottom: 15px;
        font-weight: 600;
        &::after {
          content: "";
          position: absolute;
          background-color: white;
          width: 58px;
          height: 3px;
          right: 0px;
          bottom: 0px;
        }
      }
      p {
        font-weight: 600;
      }
  
      h5 {
        font-size: 1.625rem;
      }
    }
  
    .card-color-7 {
      border: 1px solid $cor7;
      h4 {
        &::after {
          background-color: $cor7;
        }
      }
    }
    .card-color-8 {
      border: 1px solid $cor8;
      h4 {
        &::after {
          background-color: $cor8;
        }
      }
    }
    .card-color-4 {
      border: 1px solid $cor4;
      h4 {
        &::after {
          background-color: $cor4;
        }
      }
    }
    .card-color-10 {
      border: 1px solid $cor10;
      h4 {
        &::after {
          background-color: $cor10;
        }
      }
    }
  }

  #sifilis-gestantes, #meu-progresso {
    .highcharts-figure,
    .highcharts-data-table table {
      min-width: 320px;
      max-width: 660px;
      margin: 1em auto;
    }

    .highcharts-data-table table {
      font-family: Verdana, sans-serif;
      border-collapse: collapse;
      border: 1px solid #EBEBEB;
      margin: 10px auto;
      text-align: center;
      width: 100%;
      max-width: 500px;
    }

    .highcharts-data-table caption {
      padding: 1em 0;
      font-size: 1.2em;
      color: #555;
    }

    .highcharts-data-table th {
      font-weight: 600;
      padding: 0.5em;
    }

    .highcharts-data-table td,
    .highcharts-data-table th,
    .highcharts-data-table caption {
      padding: 0.5em;
    }

    .highcharts-data-table thead tr,
    .highcharts-data-table tr:nth-child(even) {
      background: #f8f8f8;
    }

    .highcharts-data-table tr:hover {
      background: #f1f7ff;
    }
  }

  #diagnostico-total-sifilis {
    .highcharts-figure,
    .highcharts-data-table table {
      min-width: 360px;
      max-width: 800px;
      margin: 1em auto;
    }

    .highcharts-data-table table {
      font-family: Verdana, sans-serif;
      border-collapse: collapse;
      border: 1px solid #EBEBEB;
      margin: 10px auto;
      text-align: center;
      width: 100%;
      max-width: 500px;
    }

    .highcharts-data-table caption {
      padding: 1em 0;
      font-size: 1.2em;
      color: #555;
    }

    .highcharts-data-table th {
      font-weight: 600;
      padding: 0.5em;
    }

    .highcharts-data-table td,
    .highcharts-data-table th,
    .highcharts-data-table caption {
      padding: 0.5em;
    }

    .highcharts-data-table thead tr,
    .highcharts-data-table tr:nth-child(even) {
      background: #f8f8f8;
    }

    .highcharts-data-table tr:hover {
      background: #f1f7ff;
    }
  }

  #casos-sifilis-adquirida {
    .highcharts-figure, .highcharts-data-table table {
      min-width: 310px; 
      max-width: 800px;
      margin: 1em auto;
    }
    
    #container {
      height: 400px;
    }
    
    .highcharts-data-table table {
      font-family: Verdana, sans-serif;
      border-collapse: collapse;
      border: 1px solid #EBEBEB;
      margin: 10px auto;
      text-align: center;
      width: 100%;
      max-width: 500px;
    }
    .highcharts-data-table caption {
      padding: 1em 0;
      font-size: 1.2em;
      color: #555;
    }
    .highcharts-data-table th {
      font-weight: 600;
      padding: 0.5em;
    }
    .highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
      padding: 0.5em;
    }
    .highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
      background: #f8f8f8;
    }
    .highcharts-data-table tr:hover {
      background: #f1f7ff;
    }
  }

  #casos-sifilis-adquirida-2 {
    .highcharts-figure, .highcharts-data-table table {
      min-width: 320px; 
      max-width: 500px;
      margin: 1em auto;
    }
    
    #container {
      height: 400px;
    }
    
    .highcharts-data-table table {
      font-family: Verdana, sans-serif;
      border-collapse: collapse;
      border: 1px solid #EBEBEB;
      margin: 10px auto;
      text-align: center;
      width: 100%;
      max-width: 500px;
    }
    .highcharts-data-table caption {
      padding: 1em 0;
      font-size: 1.2em;
      color: #555;
    }
    .highcharts-data-table th {
      font-weight: 600;
      padding: 0.5em;
    }
    .highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
      padding: 0.5em;
    }
    .highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
      background: #f8f8f8;
    }
    .highcharts-data-table tr:hover {
      background: #f1f7ff;
    }
  }

