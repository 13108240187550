.drawer {
    background-color: white;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    max-width: 0;
    width: 100%;
    text-align: center;
    user-select: none;
    z-index: 10;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
    transition: max-width 0.3s ease-out;
    overflow-x: hidden;
    display: none;


    &.active {
        max-width: 80%;

        .drawer-item {
            .drawer-item-txt {
                opacity: 1;
            }

            .menu-text {
                display: none;
            }
        }
    }

    .drawer-item {
        width: 100%;
        display: flex;
        align-items: center;
        height: 45px;

        .drawer-item-icon {
            min-width: 72px;
            position: relative;
            display: flex;
            justify-content: center;
        }

        .menu-text {
            color: #4B4B4B;
            font-size: 14px;
        }

        .drawer-item-txt {
            margin-right: 25px;
            text-align: center;
            opacity: 0;
            color: $corSecundaria;
            transition: opacity 0.5s ease-out;
            white-space: nowrap;
        }


        &:hover, &.active {
            background-color: $cor5;
            cursor: pointer;
            
            img {
                filter: brightness(0) invert(1);
            }

            span, div {
                color: white;
            }
        }
    }

    .drawer-header {
        @extend .drawer-item;
        height: 66px;
        margin-bottom: 45px;
        border-bottom: 1px solid rgba(235, 235, 235, 1);
    }

    &.mobile {
        display: block;
        max-width: 0;

        .mobile-drawer-header {
            height: max-content;
            margin-bottom: 10px;
            padding: 20px 25px;
            border-bottom: 1px solid rgba(235, 235, 235, 1);
        }

        &.active {
            max-width: 80%;
        }

        @include breakpoint(md) {
            display: none;
        }
    }

    @include breakpoint(md) {
        display: block;
        max-width: 72px;
        left: 0;
        right: auto;

        &.active {
            max-width: 320px;
        }
    }
}


.drawer-overlay {
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    background-color: black;
    opacity: 0;
    z-index: 9;
    visibility: hidden;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

    &.active {
        visibility: visible;
        opacity: 0.25;
    }
}


.bottom-nav {
    background-color: white;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    z-index: 100;
    user-select: none;

    .bottom-nav-item {
        width: calc(100% / 4);
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        color: #4B4B4B;
        border: 1px solid #D3D3D3;
        font-size: 14px;

        img {
            width: 18px;
            margin-bottom: 1px;
        }

        &:hover, &.active {
            background-color: $cor5;
            color: white;
            border: 1px solid $cor5;

            img {
                filter: brightness(0) invert(1);
            }
        }

        &:nth-child(2), &:nth-child(3) {
            img {
                width: 24px;
            }
        }

    }

    #bottom-nav-menu:hover {
        background-color: white !important;
        color: #4B4B4B;
        border: 1px solid #D3D3D3;

        img {
            filter: none;
        }
    }

    @include breakpoint(md) {
        display: none;
    }
}