#menu-politica-privacidade {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    height: 100%;

    ul > li > a {
        color: $corSecundaria;
    }
    
    ul > li > a.active, ul > li > a:hover {
        padding-left: 13px;
        color: $cor10;
        font-weight: 700;
        border-left: 1.5px solid $cor10;
    }
    
    .list-group-item, .list-group-item:focus  {
        position: relative;
        display: block;
        padding: 0;
        background-color: #fff;
        border: none;
    }
    
    .list-group-item.active, .list-group-item:hover {
        padding-left: 13px;
        color: $cor10;
        background-color: #fff;
        font-weight: 700;
        border-left: 1.5px solid $cor10;
    }
}

.body-politica-privacidade {
    background: #f4f4f4;
    position: relative;
    /* height: 100%; */
}

#content-politica-privacidade hr {
    border: 0;
    border-top: 1px solid $cor10;
    margin: 30px 0;
}

.privacidade-content {
    max-width: 100%;

    @include breakpoint(xl) {
        max-width: 75%;
    }
}

.banner-politica-privacidade {
    position: fixed;
    max-width: calc(100% - 30px);
    width: 100%;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid $cor10;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px;
    margin: 0;
    font-size: 0.875rem !important;

    @include breakpoint(md) {
        max-width: calc(100% - 30px);
        padding: 15px;
        font-size: 1rem !important;
    }

    @include breakpoint(lg) {
        max-width: 758px;
    }

    a {
        color: $cor10;
        text-decoration: underline;
    }
}