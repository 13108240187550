.monitoramento-page {
    margin-top: 1.5rem;

    .monitoramento-progress-label-mobile {
        color: white;
        padding: 5px;
        background-color: #1F8F3C;
        border-radius: 5px;
        width: max-content;
        position: relative;
    }

    .monitoramento-progress-label-mobile::after {
        content: '';
        position: absolute;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #1F8F3C;
    }

    .monitoramento-progress-container {
        position: relative;

        .monitoramento-progress-bar {
            height: 15px;
            width: 100%;
            background-color: #A5A5A5;
            border-radius: 10px;
            overflow: hidden;
            display: flex;

            .monitoramento-progress {
                height: 100%;
            }
        }

        .monitoramento-progress-bar-mobile {
            margin: 150px auto;
            width: 150px;
            height: 150px;
            background: #fefcff;
            border-radius: 50%;
            border: 1px solid #cdcbd0;

            .monitoramento-progress-mobile {
                background-color: #1F8F3C;
                height: 100%;
                position: relative;
            }
        }

        .monitoramento-progress-label {
            position: absolute;
            color: white;
            padding: 5px;
            background-color: #1F8F3C;
            border-radius: 5px;
            top: -48px;
            width: max-content;
        }

        .monitoramento-progress-label-arrow {
            position: absolute;
            top: -15px;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-top: 7px solid #1F8F3C;
        }

        .aplicacoes-card-info {
            @include breakpoint(lg){
                display: flex;
            }
            border: 1px solid #7B7B7B;
            border-radius: 5px;
            padding: 1rem;
        }
    }

    .exames-step-list {
        margin-bottom: 30px;
        overflow: hidden;
        color: lightgrey;
        display: flex;
        overflow-x: auto;
        flex-direction: row;
        padding-bottom: 20px;

        @media (max-width: 576px) {
            flex-direction: column;
        }
    }

    .exames-step-list .active {
        color: #E07716;

        &.success {
            color: #6AC65B;
        }

        &.danger {
            color: #EE4B4B;
        }
    }

    .exames-step-list li {
        list-style-type: none;
        font-size: 14px;
        float: left;
        position: relative;
        min-width: 40%;

        .exame-step-title {
            font-size: 12px;
        }

        @include breakpoint(md) {
            min-width: 20%;
        }

        @include breakpoint(lg) {
            min-width: auto;
        }

        @media (max-width:767px) {
            min-width: 22%;
        }
    }

    .exames-step-list li:before {
        content: attr(data-step);
        width: 35px;
        height: 35px;
        line-height: 32px;
        display: block;
        color: #ffffff;
        background: lightgray;
        border-radius: 50%;
        margin: 0 auto 10px auto;
        padding: 2px;
        font-family: Noto Sans;
        font-weight: bold;
        font-size: 20px;

        @media (max-width: 767px) {
            top: 100px;
        }

    }

    .exames-step-list li.active:before {
        content: '';
        background-image: url('/static/images/icone-checked.svg') !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
    }

    .exames-step-list li:after {
        content: '';
        width: 100%;
        height: 5px;
        background: lightgray;
        position: absolute;
        left: 0;
        top: calc(35px / 2);
        z-index: -1;

        @media (max-width: 767px) {
            width: 5px;
            height: 100%;
            left: auto;
            top: calc(50px / 2);
        }

    }

    .exames-step-list li.active:before,
    .exames-step-list li.active:after {
        background: #E07716;
        
    }

    .exames-step-list li.active.success:before,
    .exames-step-list li.active.success:after  {
        background: #6AC65B;
    }
    
    .exames-step-list li.active.danger:before,
    .exames-step-list li.active.danger:after  {
        background: #EE4B4B;
    }

    .exames-step-list.mobile {
        .success {
            li.active::after, li.active::before {
                background-color: #6AC65B;
            }
            .active, .border-active {
                color: #6AC65B;
                border-color: #6AC65B;
            }
        }

        .danger {
            li.active::after, li.active::before {
                background-color: #EE4B4B;
            }
            .active, .border-active {
                color: #EE4B4B;
                border-color: #EE4B4B;
            }
        }
    } 
    
    .exames-step-list li.active:after {

        @media (max-width: 767px) {
            width: 5px;
            height: 100%;
            left: auto;
            top: calc(50px / 2);
        }
    }

    span .active,
    strong .active {
        color: #E07716;
    }

    .border-active {
        border: solid 1px #E07716;
    }

    .border-not-active {
        border: solid 1px #BDBDBD;
    }

    .exames-step-list div:last-child li::after {
        @media (max-width: 767px) {
            content: none;
        }
    }

    hr.dark-hr {
        border-color: #E1E1E1;
        border-top-width: 2px;
    }


}