.new-notification {
  background-color: $cor14;
}

.new-alert {
  background-color: $cor19;
}

.dropdown-menu-notification {
  left: 50%;
  margin-left: -249px;
  border: none;
  box-shadow: 0px 5px 8px rgb(0 0 0 / 15%);
  @include breakpoint(md) {
    left: auto;
    margin-left: 0;
  }

  .noti-title {

    &:before {
      margin-left: 70px;
    }
  }

}

.dropdown-menu-alert {
  left: 50%;
  margin-left: -205px;
  @include breakpoint(md) {
    left: auto;
    margin-left: 0;
    right: -16px;
  }
  .dropdown-item {
    h6 {
      color: $cor12;
    }
  
    p {
      color: $corSecundaria;
    }
  }
}


.noti-title {
  position: relative;

  &:before {
    content: "\a";
    border-style: solid;
    border-width: 10px 13px 15px 10px;
    border-color: transparent transparent white transparent;
    position: absolute;
    left: 50%;
    margin-left: 25px;
    top: -33px;

    @include breakpoint(md) {
      left: auto;
      margin-left: auto;
      right: 25px;
    }
  }
}

.notify-item {
  display: grid !important;
  grid-template-columns: 30px fit-content(83%) 40px;
  border-bottom: 1px solid $cor16;
  padding: 20px 0 0 25px;

  button {
    display: contents;
  }

  span {
    color: $cor15;
  }
}


.dropdown-menu-alert, 
.dropdown-menu-notification
 {
  width: 340px;
  @include breakpoint(md){
    width: 400px;
  }
}