.border-container-dados {
    border: 1px solid $cor2;
}

.btn-height-auto {
    height: auto !important;
}

.btn-antecedentes-obstetricos-container {
    width: 35px;
    width: 23px;
    .btn-antecedentes-obstetricos {
        &:first-child {
            margin-bottom: 0.75rem;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: 35px;
        height: 25px;
        line-height: 20px;
        width: 23px;
        height: 20px;
        text-align: center;
    }
}

.separator-historico {
    width: 0;
    height: 17.5px;
    border: 1px solid $cor2;
    border-right: none;
}

.text-box {
    border: 1px solid $cor16;
    height: 75px;
    width: 100%;
}

.tratamento-linha-do-tempo {
    .popover-body {
        font-family: inherit !important;
        color: $corSecundaria !important;
        font-weight: 400 !important;
    }
}

.consulta-step {
    .consulta-status {
        color: $cor13;
        height: 50px;
    }
    &.first {
        .consulta-step {
            width: 60px;
        }
        .consulta-step-separator:first-child {
            visibility: hidden;
        }
    }
    &.last {
        .consulta-step {
            width: 60px;
        }
        .consulta-step-separator:last-child {
            visibility: hidden;
            margin-right: -50px;
        }
        .consulta-status {
            margin-right: -50px;
        }
    }
    &.unique {
        .consulta-step {
            width: 60px;
        }
        .consulta-step-separator {
            visibility: hidden;
        }
    }
    &.compareceu {
        .consulta-status {
            color: #4ec33b;
        }
        .consulta-step-number {
            background: #4ec33b;
        }
    }
    &.nao {
        .consulta-status {
            color: $cor12;
        }
        .consulta-step-number {
            background: $cor12;
        }
    }
    &.proxima-consulta {
        .consulta-status {
            color: #3e86ab;
        }
        .consulta-step-number {
            background: #3e86ab;
        }
    }
    &.em-atraso {
        .consulta-status {
            color: #e07716;
        }
        .consulta-step-number {
            background: #e07716;
        }
    }
    &.falta {
        .consulta-status {
            color: $cor23;
        }
        .consulta-step-number {
            background: $cor23;
        }
    }
    &.adicionar {
        cursor: pointer;
        .consulta-step-number {
            background: $cor8;
        }
    }
    &.nova-consulta {
        .consulta-step-number {
            cursor: pointer;
            background: $cor24;
        }
    }
    &.nova-consulta-prev {
        .consulta-step-separator:first-child {
            border-bottom: 5px dashed $cor24;
            background-color: transparent;
        }
    }
    &.nova-consulta-next {
        .consulta-step-separator:last-child {
            border-bottom: 5px dashed $cor24;
            background-color: transparent;
        }
    }
    &.nao-prev {
        .consulta-step-separator:first-child {
            background: $cor12;
        }
    }
    &.nao-next {
        .consulta-step-separator:last-child {
            background: $cor12;
        }
    }
    &.compareceu-prev {
        .consulta-step-separator:first-child {
            background: #4ec33b;
        }
    }
    &.compareceu-next {
        .consulta-step-separator:last-child {
            background: #4ec33b;
        }
    }
    &.proxima-consulta-prev {
        .consulta-step-separator:first-child {
            background: #3e86ab;
        }
    }
    &.proxima-consulta-next {
        .consulta-step-separator:last-child {
            background: #3e86ab;
        }
    }
    &.em-atraso-prev {
        .consulta-step-separator:first-child {
            background: #e07716;
        }
    }
    &.em-atraso-next {
        .consulta-step-separator:last-child {
            background: #e07716;
        }
    }
    &.adicionar-prev {
        .consulta-step-separator:first-child {
            background: $cor8;
        }
    }
    &.adicionar-next {
        .consulta-step-separator:last-child {
            background: $cor8;
        }
    }
    &.falta-prev {
        .consulta-step-separator:first-child {
            background: $cor23;
        }
    }
    &.falta-next {
        .consulta-step-separator:last-child {
            background: $cor23;
        }
    }
    .consulta-step-number {
        height: 42px;
        width: 42px;
        color: white;
        font-weight: 700;
        font-size: 22px;
        background: #a5a5a5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .consulta-step-separator {
        width: 100px;
        background-color: #a5a5a5;
        height: 5px;
    }
}

.table-pre-natal {
    td,
    th {
        vertical-align: middle !important;
        border-bottom: 1px solid #e1e1e1;
        min-width: 196px;
    }
    tr:first-child {
        td,
        th {
            color: $cor10;
            font-family: Noto Sans;
            font-size: 16px;
            font-weight: 700;
            border-top: none;
        }
    }
    tr:not(:first-child) {
        th {
            font-size: 16px;
            font-weight: 400;
            border: 1px solid #e1e1e1;
        }
        td {
            font-size: 15px;
            font-weight: 500;
        }
        td:first-child {
            border-left: 1px solid #e1e1e1;
        }
    }
    tr:nth-child(2n + 1):not(:first-child) {
        background-color: #fafafa;
    }
    tr:not(:first-child) td:last-child {
        border-right: 1px solid #e1e1e1;
    }
}

.table-pre-natal#tabela-resultados, .table-pre-natal#table-multidiciplinar-anamnese {
    margin: 2rem 0 0 0 !important;
    td,
    th {
        min-width: auto !important;
    }
    th {
        vertical-align: baseline !important;
    }
}

.default-table {
    border: 1px solid #e1e1e1;
    th,
    td {
        font-weight: 400 !important;
        font-size: 16px;
        color: $corSecundaria;
        vertical-align: middle;
        padding: 1.25rem 1rem;
    }
    tr th {
        font-weight: 700 !important;
    }
    tr:nth-child(2n) {
        background-color: #f5f5f5;
    }
}

.table-multidisciplinar {
    border: 1px solid #e1e1e1;
    th,
    td {
        font-weight: 400 !important;
        font-size: 16px;
        color: $corSecundaria;
    }
    tr:nth-child(2n) {
        background-color: #f5f5f5;
    }
    .button-ver-detalhes {
        background-color: $cor2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
    }
}

.table-exames {
    tr:nth-child(2n) {
        background-color: #fafafa;
    }
    tr:not(:first-child) {
        border: 1px solid #e1e1e1;
    }
    td,
    th {
        border: none;
    }
    th {
        font-weight: 700 !important;
        font-size: 16px !important;
        color: $cor10 !important;
    }
    td {
        font-weight: 400 !important;
        font-size: 16px !important;
    }
}

.table-title {
    font-weight: 600;
    font-size: 16px;
    color: #7b7b7b;
}

.table-aplicacoes {
    td:not(.collapsed) img {
            transform: rotate(180deg);
    }
    tr:not(:first-child):nth-child(2n + 1) {
        background-color: #fafafa;
    }
    tr:not(:first-child) {
        border: 1px solid #e1e1e1;
    }
    td,
    th {
        vertical-align: middle !important;
        border: none;
    }
    th {
        font-weight: 700 !important;
        font-size: 16px !important;
        color: $cor10 !important;
    }
    td {
        font-weight: 400 !important;
        font-size: 16px !important;
    }
}

.legenda-vacinas {
    &.imunizado {
        color: white;
        background-color: #29b74e;
    }
    &.sem-informacao {
        color: #4b4b4b;
        font-size: 16px;
        font-weight: 400;
        background-color: #e8e8e8;
    }
    border-radius: 5px;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    font-size: 16px;
}

.status-aplicacao {
    border-radius: 20px;
    text-align: center;
    padding: 0.2rem 0.3rem;
    width: 100%;
    &.aplicada {
        color: #1f8f3c;
        border: 2px solid #1f8f3c;
        background-color: white;
    }
    &.planejada {
        color: #797979;
        border: 2px solid #797979;
        background-color: white;
    }
}

.margin-status-icon {
    padding-left: calc(28px + 1rem);
}

.date-input {
    display: flex;
    flex-direction: row;
    width: max-content;
    height: max-content;
    input {
        border-right: none;
        border-radius: 5px 0 0 5px;
        max-width: 178px !important;
        width: 100%;
        font-variant-numeric: tabular-nums;
    }
    .input-group-text {
        border: 2px solid $cor10;
        background-color: $cor10;
    }
}

input[type="checkbox"].custom-control-input:not(:checked)+.custom-control-label::before {
    border: 2px solid $corSecundaria !important;
}

input[type="checkbox"].custom-control-input:checked+.custom-control-label::after {
    background-color: #265974;
    border-radius: 3px;
}

input[type="checkbox"].custom-control-input+.custom-control-label::after,
input[type="checkbox"].custom-control-input+.custom-control-label::before {
    transition: background-color 0s;
    width: 18px !important;
    height: 18px !important;
    outline: none;
}

.dente-container {
    width: 60px;
    height: 70px;
    #dente {
        .mid,
        .top,
        .bottom,
        .left,
        .right {
            display: none;
        }
    }
    $sides: "top",
    "bottom",
    "left",
    "mid",
    "right";
    @each $side in $sides {
        &.#{$side} {
            #dente {
                .#{$side} {
                    display: block;
                }
            }
        }
    }
    &.azul {
        #dente .body {
            stroke: $cor10;
        }
        #dente .mid,
        .top,
        .bottom,
        .left,
        .right {
            fill: $cor10;
        }
    }
    &.roxo {
        #dente .body {
            stroke: $cor8;
        }
        #dente .mid,
        .top,
        .bottom,
        .left,
        .right {
            fill: $cor8;
        }
    }
    &.disabled {
        #dente .body {
            stroke: #a6a6a6;
        }
        #dente .mid,
        .top,
        .bottom,
        .left,
        .right {
            fill: #a6a6a6;
        }
    }
}

.referencia-link--ver-mais {
    color: $cor10;
    text-decoration: underline;
    text-underline-offset: 0.25em;
    &:hover {
        text-decoration: underline;
    }
}

.dente-status {
    border: 1px solid $cor10;
    font-size: 16px;
    font-weight: 400;
    color: $corSecundaria;
    width: 30px;
    padding: 0 0.25rem;
}

.data-consulta-odontologica {
    background-color: #29b74e;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    font-size: 16px;
    color: white;
    border-radius: 5px;
}

.badge-tratamento-status {
    border-radius: 2rem;
    padding: 0.15rem 1rem;
    white-space: nowrap;
    &--finished {
        @extend .badge-tratamento-status;
        border: 2px solid #1f8f3c;
        color: #1f8f3c;
    }
    &--in-progress {
        @extend .badge-tratamento-status;
        border: 2px solid #a664b8;
        color: #a664b8;
    }
    &--canceled {
        @extend .badge-tratamento-status;
        border: 2px solid #d4092d;
        color: #d4092d;
    }
    &--planned {
        @extend .badge-tratamento-status;
        border: 2px solid #e07716;
        color: #e07716;
    }
}

.pagination {
    color: $corSecundaria;
    font-size: 16px;
    font-weight: 600;
    .page-item.active .page-link {
        background-color: $cor10;
        border-color: $cor10;
        border-radius: 50%;
    }
    .page-link,
    .page-link:hover {
        height: 40px;
        color: $corSecundaria;
        font-weight: 600;
        border: none;
    }
    .page-item:not(.next):not(.previous) {
        .page-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
        }
    }
}

.btn-collapse[aria-expanded="false"] #colapse-icon {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.container-status-grafico {
    padding: 0.2rem 1rem;
    background-color: $cor8;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    width: fit-content;
}

.percentis-legenda-container {
    div {
        font-size: 14px;
        border: 1px solid $cor8;
        border-radius: 100%;
        padding: 0.25rem;
    }
}

.highcharts-tooltip {
    background-color: white;
    span {
        background-color: white;
    }
}

.historico-evolucao-container {
    display: flex;
    flex-direction: column;
    border: 1px solid $cor10;
    border-radius: 5px;
    padding: 1rem 1.5rem;
    width: 237px;
    min-width: 237px;
    height: 136px;
}

#btn-equipe-acoes {
    &::after {
        display: none;
    }
}

.adicionar-diagnostico-consulta-odontologica {
    ul.nav {
        border: 0;
        height: fit-content;
    }
    .dot-step {
        height: 50px;
        width: 50px;
        color: white;
        background-color: #a6a6a6;
        border-radius: 50%;
        display: block;
        margin-bottom: 1rem;
        text-align: center;
        line-height: 50px;
        font-size: 1.5rem;
        font-weight: bold;
    }
    [data-dente="step-1"] {
        cursor: pointer;
    }
    a {
        &.nav-link {
            position: relative;
        }
        &.nav-link:after {
            position: absolute;
            height: 3px;
            min-width: 100%;
            width: 100%;
            border-radius: 5px;
            background-color: #a6a6a6;
            content: " ";
            bottom: 0px;
            left: 0;
            transform: translateY(50%);
        }
        &.nav-link.active:after {
            min-width: 100%;
            width: 100%;
            background-color: #a664b8;
        }
        &.active {
            .dot-step {
                background-color: #a664b8;
            }
        }
    }
    li.nav-item {
        padding: 5px;
        height: fit-content;
    }
    .modal-dentes-selecao {
        background-color: #e4eff5;
        padding: 0.35rem 0.75rem;
        border-radius: 0.25rem;
    }
    .adicionar-dente-button {
        background-color: transparent;
        color: $cor10;
    }
    .disgnosticos {
        .container-diagnostico-option {
            cursor: pointer;
        }
        .diagnostico-opcao {
            border-radius: 5px;
            padding: 0.25rem 0.5rem;
            margin: 0.25rem;
            font-weight: bold;
            margin-top: 0.5rem;
            &.roxo {
                border: 1px solid $cor8;
                background-color: $cor8;
                color: white;
            }
            &.azul {
                border: 1px solid $cor10;
                color: $cor10;
            }
        }
    }
    button.adicionar-diagnostico {
        background-color: transparent;
        border: 2px dashed $cor10;
        color: $cor10;
        border-radius: 5px;
        padding: 0.25rem 0.5rem;
        margin: 0.25rem;
        font-weight: bold;
        margin-top: 0.5rem;
        width: 100%;
    }
    .diagnostico-checkboxes-container {
        .diagnostico-checkboxes {
            display: flex;
            flex-direction: column;
            background-color: $cor6;
            padding: 0.25rem 1rem 1rem 1rem;
            border-radius: 0.35rem;
            .close-button {
                font-size: 2rem;
                color: $cor10;
                outline: none;
                border: none;
                background-color: transparent;
            }
            &-list {
                display: flex;
                gap: 0.5rem;
                align-items: flex-start;
                justify-content: space-between;
            }
            &-col {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 0.5rem;
                max-width: 33.333333%;
            }
        }
    }
    .resumo-diagnostico {
        &-button {
            border-bottom: 2px solid $cor10;
            margin-bottom: 1rem;
            cursor: pointer;
            transition: all ease 0.3s;
            &[aria-expanded="false"] .resumo-diagnostico-button-icon img {
                transform: rotate(180deg);
            }
        }
        .card-diagnostico-resumo {
            background-color: #e4eff5;
            padding: 0.75rem;
            margin-bottom: 0.75rem;
            border-left: 5px solid #3e86ab;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            .resumo-left {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                .badge {
                    display: flex;
                    gap: 0.5rem;
                    border: 1px solid $cor10;
                    padding: 0.35rem 0.5rem;
                    border-radius: 2rem;
                    align-items: center;
                    justify-content: space-between;
                    gap: 1rem;
                    cursor: pointer;
                    .badge-icon {
                        background: $cor10;
                        color: white;
                        width: 15px;
                        height: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0.25rem;
                        border-radius: 100%;
                    }
                }
            }
            .resumo-right-close {
                background: $cor10;
                color: white;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.25rem;
                border-radius: 100%;
                cursor: pointer;
            }
        }
    }
}

.dropdown-fumo-item {
    border: 1px solid $cor10;
    border-bottom: 0px;
}

.dropdown-fumo-item:last-child {
    border: 1px solid $cor10;
}

.status-exame {
    border-radius: 20px;
    padding: .2rem 0.5rem;
    width: 109px;
    text-align: center;
    background-color: white;
    border: 2px solid #797979;
    &.realizado {
        color: $cor7;
        border-color: $cor7;
        background-color: white;
    }
    &.planejado {
        color: $cor8;
        border-color: $cor8;      
    }
    &.pendente {
        color: $cor4;
        border-color: $cor4;
    }
}

#linha-do-tempo {
    .popover-body {
        padding: 1rem 2rem;
        color: $corSecundaria !important;
        font-weight: 400;
        strong {
            font-weight: 600;
        }
    }
}

#linha-do-tempo {
    .dropdown-menu {
        border: 1px solid $cor8;
        border-radius: 0.25rem;
        .dropdown-item {
            padding: 0.5rem 1rem;
            color: $cor8;
            font-weight: 400;
            &:hover {
                background-color: $cor8;
                color: white;
            }
        }
    }
}

#referencias .tabs-referencias {
    .nav-link {
        padding: 0.2rem 0.3rem !important;
        color: $corSecundaria !important;
        border-radius: 5px !important;
        margin-right: 0.5rem;
    }
    .nav-link.active {
        background-color: $cor8 !important;
        color: white !important;
    }
}

.timeline {
    height: 9px;
    border: 2px solid #BDBDBD;
  }

.timeline-full {
    height: 9px;
    border: 2px solid #A664B8;
    background-color: #A664B8;
}

.indicador-trimestre {
    height: 16px;
    width: 3px;
    background-color: #BDBDBD;
  }

.indicador-trimestre-full {
    height: 16px;
    width: 3px;
    background-color: #A664B8;
}

.tempo-gestacao-tag {
    padding: 10px;
    border-radius: 30px;
    background: rgba(166, 100, 184, 0.20);
    color: #A664B8;
}

#legenda-trimestres{
    justify-content: space-between;
}