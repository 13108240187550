.login {
    .login__bg {
        background-repeat: no-repeat;
        background-color: $cor2;
        height: 86vh;
        background-size: cover;
        background-position: center;
        @include breakpoint (md) {
            background-image: url("../../static/images/new-bg.png");
            height: 90vh;
        }
    }
    .title,
    .title-color8 {
        color: $cor4;
        position: relative;
        font-size: 26px;
        @include breakpoint(md) {
            font-size: 4rem;
        }
        &::before {
            content: "";
            display: block;
            width: 167px;
            height: 3px;
            background-color: $cor3;
            position: absolute;
            bottom: -13px;
        }
        >span {
            color: $cor3;
            font-weight: bold;
        }
    }
    .title-color8 {
        color: $cor8;
        &::before {
            background-color: $cor8;
        }
    }
    .btn-ler-politica-privacidade {}
    .about-salus {
        .text-18 {
            font-size: 1.125rem;
        }
        h3 {
            color: $cor3;
            font-weight: bold;
        }
    }
}

.noti-title {
    a {
        color: $cor10;
        font-weight: 600;
    }
}

.boletimsalus {
    select {
        text-align-last: center;
    }
}

.border-bottom-color-7 {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        color: $cor7;
        background-color: $cor7;
        width: 40px;
        height: 3px;
        left: 0px;
        bottom: -10px;
    }
}

.border-bottom-color-8 {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        color: $cor8;
        background-color: $cor8;
        width: 40px;
        height: 3px;
        left: 0px;
        bottom: -10px;
    }
}

.border-bottom-color-10 {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        color: $cor10;
        background-color: $cor10;
        width: 40px;
        height: 3px;
        left: 0px;
        bottom: -10px;
    }
}

#tabBoletim,
#tabSalaDeSituacao {
    .nav-link {
        color: #a6a6a6;
        letter-spacing: -0.03em;
        padding: 0;
        @include breakpoint(lg) {
            padding: 30px 50px 7px;
            font-weight: 600;
            letter-spacing: normal;
            padding: 0 16px;
        }
    }
    .nav-item {
        &:not(:last-child) {
            .nav-link {
                margin-right: 25px;
            }
        }
    }
    .nav-item.show .nav-link,
    .nav-link.active {
        color: $cor8;
        border: none;
        border-bottom: 3px solid $cor8;
    }
}

#tabContentBoletim {
    p {
        margin-bottom: 30px;
    }
    .card {
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;
        .card-body {
            padding-bottom: 0;
        }
        .indicador {
            position: relative;
            margin-bottom: 15px;
            .valor-indicador {
                font-size: 2.875rem;
                line-height: 63px;
                text-align: right;
                letter-spacing: -0.03em;
                font-weight: 600;
            }
            .porcentagem-indicador {
                font-size: 30px;
                font-weight: normal;
            }
            &::after {
                content: "";
                position: absolute;
                background-color: white;
                width: 102px;
                height: 3px;
                right: 0px;
                bottom: -4px;
            }
        }
        p {
            font-weight: 600;
        }
        h5 {
            font-size: 1.625rem;
        }
    }
    .card-color-7 {
        border: 1px solid $cor7;
        .indicador {
            &::after {
                background-color: $cor7;
            }
        }
    }
    .card-color-8 {
        border: 1px solid $cor8;
        .indicador {
            &::after {
                background-color: $cor8;
            }
        }
    }
    .card-color-4 {
        border: 1px solid $cor4;
        .indicador {
            &::after {
                background-color: $cor4;
            }
        }
    }
    .card-color-10 {
        border: 1px solid $cor10;
        .indicador {
            &::after {
                background-color: $cor10;
            }
        }
    }
}

.boletimsalus .select2.select2-container {
    border: 2px solid $cor8;
}