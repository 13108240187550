.no-more-tables {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }
    thead th {
        position: absolute;
        top: -9999px;
        left: -9999px;
        border: 1px solid #ccc;
    }
    td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-top: 40px;
        white-space: normal;
        text-align: left;
        &:last-of-type {
            background-color: #eaeaea;
            padding-left: 12px;
            padding-top: 40px;
        }
        &:before {
            position: absolute;
            top: 6px;
            left: 14px;
            text-align: center;
            font-weight: bold;
            content: attr(data-title);
        }
    }
    a,
    button {
        width: 100%;
    }
    @include breakpoint(lg) {
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: revert;
        }
        thead th {
            vertical-align: bottom;
            position: initial;
            border: none;
            border-bottom: 2px solid #dee2e6;
        }
        td {
            vertical-align: middle;
            position: relative;
            padding: 10px;
            &:last-of-type {
                background-color: transparent;
                padding-top: 0px;
            }
            &:before {
                content: none;
            }
        }
        a,
        button {
            width: auto;
        }
    }
}

.table-modulos {
    th {
        padding: 10px !important;
    }
    td:before {
        color: $cor10;
    }
    td.actions {
        background-color: transparent !important;
        @include breakpoint(lg) {
            padding: 10px;
        }
    }
}

.table-equipe {
    .row:nth-child(2)>div {
        padding: 0;
    }
    .row:nth-child(3) {
        div:first-child {
            padding-left: 0;
            display: flex;
            align-items: center;
        }
        div:last-child {
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            ul {
                margin-bottom: 0;
            }
        }
    }
    .btn-table-action {
        padding-top: 3px;
        padding-bottom: 3px;
    }
    th {
        color: #3e86ab !important;
    }
    #table-equipe {
        overflow-x: auto;
    }
    #table-equipe.dataTable {
        border-collapse: collapse !important;
    }
    #table-equipe>thead>tr>th {
        border: none !important;
    }
    #table-equipe>tbody>tr {
        border: 1px solid #e1e1e1;
    }
    #table-equipe>tbody>tr:nth-child(even) {
        background-color: #fafafa;
    }
    #table-equipe>tbody>tr:nth-child(odd) {
        background-color: #fff;
    }
    #table-equipe>tbody>tr td {
        vertical-align: middle;
        font-size: 16px;
        font-weight: 400;
    }
    div#table-equipe_info {
        color: #4b4b4b !important;
        font-weight: 700 !important;
    }
    div.dataTables_info {
        align-self: center;
    }
    #table-equipe_filter {
        display: none;
    }
    .page-item.active .page-link {
        background-color: #3e86ab;
        border-color: #3e86ab;
        border-radius: 50%;
    }
    .page-link,
    .page-link:hover {
        padding: 10px 15.5px;
        color: #4b4b4b;
        font-weight: 600;
        border: none;
    }
    .page-item {
        border: 0;
    }
    #table-equipe_next>a {
        padding-right: 0 !important;
    }
    #table-equipe_wrapper>.row {
        margin-right: 0;
        margin-left: 0;
    }
}