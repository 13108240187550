.button .button_item {
    transition: none !important;
}

.button .button_item:hover {
    background-color: #3E86AB;
    color: white;
}

.dropdown .action:hover {
    background-color: #3E86AB;
    color: white;
}

.action,
.button {
    outline: 1px solid #b0d7ec;
    color: #3E86AB;
}

.general-tabs .nav-item.show .nav-link,
.general-tabs .nav-link.active {
    color: $cor10;
}

.general-tabs .nav-item.show .nav-link,
.general-tabs .nav-link {
    border-color: #C4C4C4;
}

.general-tabs .nav-link:hover {
    border-color: #C4C4C4;
    color: $cor10;
}

.table-border-top-0 {
    border-top: none !important;
}

#perfilUsuarioTab {
    li {
        width: 50%;
        a {
            white-space: normal;
            font-size: 1rem;
        }
    }
    @include breakpoint(md) {
        font-size: 1.25rem;
        li {
            width: max-content;
            a {
                font-size: 1.25rem;
            }
        }
    }
}

.table-historico {
    tr:not(:first-child):nth-child(2n + 1) {
        background-color: #FAFAFA;
    }
    tr:not(:first-child) {
        border: 1px solid #E1E1E1;
    }
    td,
    th {
        vertical-align: middle !important;
        border: none;
    }
    th {
        font-weight: 700 !important;
        font-size: 16px !important;
        color: $cor22 !important;
    }
    td {
        color: $corSecundaria !important;
        font-weight: 400 !important;
        font-size: 16px !important;
    }
}