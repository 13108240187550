.home {
    section:first-child>.home-container {
        margin-top: 150px;
        @include breakpoint(md) {
            margin-top: 200px;
        }
    }
    .home-container {
        margin-top: 100px;
        position: relative;
        margin-left: 16px;
        margin-right: 16px;
        @include breakpoint(md) {
            margin-left: 120px;
            margin-right: 120px;
        }
    }
    .home__bg {
        display: flex;
        justify-content: center;
        background-color: $cor2;
        // background-size: contain;
        // background-repeat: no-repeat;
        // background-position: center;
        // @include breakpoint (md) {
        //     background-image: url("../../static/images/landing-page-salus.png");
        //     height: 80vh;
        // }
    }
    .img-background {
        display: none;
        @include breakpoint(lg) {
            display: block;
            position: absolute;
            right: -80px;
            top: -150px;
        }
    }
    .modulos {
        .nav-tabs {
            width: fit-content;
            border-color: #737272;
        }
        .nav-link {
            color: #737272;
            font-weight: 700;
            border: none;
            padding: .5rem 2rem;
        }
        .nav-link.active {
            background-color: transparent;
            color: $cor3;
            border-color: $cor3;
            border-bottom: 4px solid $cor3;
        }
        
        margin-top: 30px;
        @include breakpoint(md) {
            margin-top: 100px;
        }
        .card {
            box-shadow: 0px 5px 8px rgb(0 0 0 / 15%);
            margin-bottom: 20px;
            transition: background-color 0.3s ease-out;
            @include breakpoint(lg) {
                height: 330px;
            }
            .icone-container {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $cor3;
                padding: 10px;
                height: 60px;
                width: 60px;
                @include breakpoint(md) {
                    height: 64px;
                    width: 64px;
                }
                border-radius: 5px;
            }
            .lancamento {
                &.btn {
                    cursor: pointer;
                }
                cursor: default;
                min-height: 2.5rem !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem;
                background-color: $cor10;
                font-weight: 700;
                color: white;
                border-radius: 5px;
            }
            &:hover .btn-certificado {
                background-color: white;
                color: $cor3;
            }
            .btn-certificado {
                height: 37px;
                font-weight: 700;
            }
            .em-breve {
                cursor: default;
                min-height: 2.5rem !important;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem;
                background-color: #A6A6A6;
                font-weight: 600;
                color: white;
                border-radius: 5px;
            }
        }
        .pequeno {
            height: 290px !important;
            min-width: 305px;
            @media screen {
                @media (max-width: 375px) {
                    height: 100% !important;
                    margin-bottom: 10px;
                }
            }
        }
        .interrogacao {
            width: 20px;
            height: 20px;
        }
        .card:hover {
            background-color: $cor3;
            p {
                color: white;
            }
            .icone-container {
                background-color: white;
                img {
                    filter: brightness(0) saturate(100%) invert(30%) sepia(79%) saturate(352%) hue-rotate(156deg) brightness(86%) contrast(96%);
                }
            }
            .interrogacao {
                filter: brightness(0) invert(1);
            }
        }
    }
    #select-estado {
        border: 1px solid $cor2;
        border-radius: 5px;
        padding: 0.5rem;
        width: 100%;
        background-color: white;
        color: $cor10;
        text-align: center;
        font-weight: 400;
        font-size: 1.2rem;
    }
    .section-title {
        font-size: 4rem;
        color: $cor10;
        .destaque {
            color: $cor11;
        }
         ::before {
            content: "";
            display: block;
            width: 167px;
            height: 3px;
            background-color: $cor4;
            position: absolute;
            bottom: -13px;
        }
        @media screen {
            @media (max-width: 500px) {
                font-size: 3rem !important;
            }
            @media (max-width: 400px) {
                font-size: 2.2rem !important;
            }
        }
    }
    .legenda-azul {
        width: 2rem;
        height: 1.2rem;
        background-color: $cor10;
    }
    .nav-beneficio {
        .nav-link {
            margin-right: 1rem;
            border: 1px solid $cor2;
            color: $cor10;
        }
    }
    #beneficios-modulo-content {
        padding: 4rem;
        min-height: 532px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../static/images/salus-beneficio-background.png"), linear-gradient($cor10, $cor10);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-blend-mode: overlay;
        .card-beneficio {
            margin: 1.5rem 0;
            img {
                margin-bottom: 1rem;
            }
            p {
                margin-bottom: 0;
            }
            border-radius: 5px;
            @include breakpoint(md) {
                height: 250px;
            }
            @include breakpoint(lg) {
                height: 250px;
            }
            @include breakpoint(xl) {
                height: 190px;
            }
            padding: 1.75rem 2.25rem;
            border: 2px solid white;
            background-color: $cor10;
            color: white;
            .card-image {
                width: 50px;
                height: 50px;
            }
            .texto-destaque {
                font-weight: 700;
            }
            @media screen {
                @media (max-width: 500px) {
                    width: 100%;
                }
            }
            .pagination-beneficios {
                margin-top: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding-top: 1rem;
                border-top: 1px solid white;
                margin-top: 2rem;
                .text-pagination {
                    font-size: 16px;
                    color: white;
                    font-weight: 600;
                    line-height: 22px;
                }
                .prev,
                .next {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: 1px solid white;
                    border-radius: 100%;
                    width: 44px;
                    height: 44px;
                    img {
                        width: 10px;
                        height: 20px;
                        margin: 0;
                    }
                }
            }
        }
        @media screen {
            @media (max-width: 500px) {
                padding: 1rem;
            }
        }
    }
    .lista-etapas-adesao {
        padding: 2rem 0;
        background-color: #f9f9f9;
        .etapa-adesao {
            &:not(.mobile) {
                .numeracao-etapa {
                    height: 72px;
                    width: 80px !important;
                    border-radius: 100%;
                    background-color: $cor10;
                    font-size: 24px;
                    font-weight: 700;
                    color: white;
                    margin-right: 1.5rem;
                }
                .card-etapa-adesao {
                    .etapa-title {
                        font-weight: 700;
                        font-size: 24px;
                    }
                    .etapa-subtitle {
                        font-size: 18px;
                        font-weight: 400;
                    }
                    .icone-container-adesao {
                        @media screen {
                            @media (max-width: 375px) {
                                gap: 20px;
                            }
                        }
                        background-color: $cor10;
                        padding: 5px;
                        @include breakpoint(md) {
                            padding: 10px;
                        }
                        border-radius: 5px;
                    }
                    width: 100%;
                    padding: 1.25rem;
                    background-color: white;
                    border-radius: 5px;
                    border-right: 20px solid $cor10;
                    box-shadow: 0px 4px 4px rgba(0,
                    0,
                    0,
                    0.25);
                }
            }
            &.mobile {
                .numeracao-etapa {
                    height: 44px;
                    width: 44px !important;
                    border-radius: 100%;
                    background-color: $cor10;
                    font-size: 24px;
                    font-weight: 700;
                    color: white;
                    margin-right: 1rem;
                }
                .card-etapa-adesao {
                    .etapa-title {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .etapa-subtitle {
                        margin-top: 1rem;
                        font-size: 16px;
                        font-weight: 400;
                        color: $cor13;
                    }
                    .icone-container-adesao {
                        img {
                            width: 24px;
                            height: 24px;
                        }
                        background-color: $cor10;
                        padding: 5px;
                        border-radius: 5px;
                        margin-right: 1rem;
                    }
                    width: 100%;
                    padding: 0.8rem;
                    background-color: white;
                    border-radius: 5px;
                    border-right: 8px solid $cor10;
                    box-shadow: 0px 4px 4px rgba(0,
                    0,
                    0,
                    0.25);
                }
            }
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }
    .cards-row {
        @media screen {
            @media (max-width: 375px) {
                gap: 20px;
            }
        }
    }
    .title-small {
        @media screen {
            @media (max-width: 450px) {
                font-size: 3rem;
            }
        }
    }
    .botao-adesao {
        content: "Acessar formulário de Adesão ao Salus";
        @media screen {
            @media (max-width: 450px) {
                content: "Solicitar adesão";
            }
        }
    }
}

.modal-sm {
    .icone-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $cor3;
        padding: 10px;
        height: 60px;
        width: 60px;
        @include breakpoint(md) {
            height: 64px;
            width: 64px;
        }
        border-radius: 5px;
    }
}