.centraldeajuda {
	background-color: #f4f4f4;
	min-height: 100vh;

	.container {
		width: 100%;

		@include breakpoint(xl) {
			width: 75%;
		}
	}

	label {
		font-weight: 600;
	}
}

.bootstrap-tagsinput {
	border: 2px solid $cor2 !important;
    border-radius: 5px;
    padding: 5px;
    width: 100%;

    &:hover, &.has-focus {
        border-color: $cor10 !important;
		outline: 0 none;
        box-shadow: none;
    }

	.label-info {
		display: inline-block;
		background-color: $cor5;
		padding: 2px 10px;
		border-radius: 20px;
		color: white;
		margin-top: 2px;
		margin-bottom: 2px;
	}

	& .tag [data-role="remove"]:after {
		content: '\00d7';
	}

}