$step-sizes: (
  2: calc((100% / 2) - 2px), 
  3: calc((100% / 3) - 2px), 
  4: calc((100% / 4) - 2px),
  5: calc((100% / 5) - 2px),
  6: calc((100% / 6) - 2px),
  7: calc((100% / 7) - 2px),
);

@each $size, $width in $step-sizes {
  .steps-#{$size} {
    li {
      width: 100%;

      @include breakpoint(md) {
        width: $width;
      }
    }

    &.non-responsive li{
      width: $width;
    }
  }
}

.stepper {
  display: flex;
  flex-direction: row;
  font-variant-numeric: tabular-nums;
  justify-content: space-between;

  li {
    list-style-type: none;
    display: none;
    font-size: 1.25rem;
    font-weight: 600;
    color: #BDBDBD;
    flex-wrap: wrap;
    align-items: center;
  
    span:first-child {
      background: #BDBDBD;
      font-size: 1.375rem;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      color: white;
      text-align: center;
    } 

    span:last-child {
      margin-left: 0.75rem;
    }

    &::after {
      content: '';
      height: 3px;
      background-color: #BDBDBD;
      width: 100%;
      margin-top: 15px;
    }

    @include breakpoint(md) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 0.75rem;

      span:first-child {
        margin-bottom: 8px;
      }

      span:last-child {
        margin-left: 0;
      }

      &::after {
        margin-top: 11px;
      }
    }
  }

  li.active {
    display: flex;
    color: $cor7;

    span:first-child, &::after {
      background: $cor7;
    }
  }

  li.complete {
    span:first-child {
      display: none;
    }

    &::before {
      content: '';
      background-color: #BDBDBD;
      background-image: url("/static/images/icone-checked.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-bottom: 8px;
    }
  }

  &.pink {
    li.active {
      color: $cor8;
  
      span:first-child, &::after {
        background: $cor8;
      }
    }
  }

  &.non-responsive li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.75rem;

    span:first-child {
      margin-bottom: 8px;
    }

    span:last-child {
      margin-left: 0;
    }

    &::after {
      margin-top: 11px;
    }
    
  }

}

.mobile-steps {
  color: #BDBDBD;
  display: flex;
  justify-content: flex-end;
  font-variant-numeric: tabular-nums;
  margin: 0;

  li {
    list-style-type: none;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    &.active {
      color: white;
      background-color: $cor7;
      border-radius: 50%;
    }
  }

  &.pink li.active {
    background-color: $cor8;
  }
}