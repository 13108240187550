$cor1: #FB7C06;
$cor2: #B0D7EC;
$cor3: #265974;
$cor4: #E07716;
$cor5: #1B4D67;
$cor6: #F4F4F4;
$corSecundaria: #4B4B4B;
$cor7: #1F8F3C;
$cor8: #A664B8;
$cor8-hover: #703B7E;
$cor9: #707070;
$cor10: #3E86AB;
$cor11: #344B74;
$cor12: #D4092D;
$cor13: #7B7B7B;
$cor14: #EEF4F6;
$cor15: #8F8F92;
$cor16: #D4D4D4;
$cor17: #6BB2D7;
$cor18: #BA83C9;
$cor19: #FDDEE4;
$cor20: #F1F0F5;
$cor21: #000000;
$cor22: #373737;
$cor23: #D73551;
$cor24: #BDBDBD;