.card-default {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
  padding: 24px 32px;
}

.card-vinculo {
  background-color: $cor6;
  .pill-validacao-vinculo {
    display: flex;
    height: 29px;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    border: 1px solid $cor7;
    background: rgba(31, 143, 60, 0.15);
    color: $cor7;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    img {
        margin-left: .5rem;
        width: 16px;
        height: 16px;
    }
    &.nao-validado {
        border: 1px solid $cor12;
        background: #FDDEE4;
        color: $cor12;
    }
  }
}

.card-ultimos-acessos {
  background-color: white;

  &:hover {
    background-color: $cor7;

    span {
      color: white !important;
    }

    img {
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
      -moz-filter: brightness(0) invert(1);
    }
  }
}

.card-mais-vistos {
  background-color: white;

  &:hover {
    background-color: $cor8;

    span {
      color: white;
    }

    .card-icone {
      filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
      -webkit-filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
      -moz-filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
    }

  }
}

.card-favoritos {
  background-color: white;
  color: $corSecundaria;


  &:hover {
    background-color: $cor4;

    span {
      color: white !important;
    }

    img {
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
      -moz-filter: brightness(0) invert(1);
    }
  }
}


$statusList: (
  0: $cor3,
  1: $cor4,
  2: $cor12,
  3: $cor13,
  4: $cor7,
  5: $cor21,
  6: $cor10
);

@each $statusName, $color in $statusList {
  .status-#{$statusName} {
    color: $color;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $color;
      margin-right: 10px;
    }
  }
}

.card-pacientes {
  border: 1px solid $cor2;
  padding: 15px;
  position: relative;

  .card-pacientes-actions {
    padding: 15px 0;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;

    .btn-color-8:not(:last-child) {
      margin-bottom: 15px;
    }

    @include breakpoint(md) {
      position: absolute;

      .btn-color-8 {
        width: 140px;
        margin-bottom: 0;
      }
    }
  }
}

.card-vinculos {
  border: 1px solid $cor2;
  padding: 15px;
  width: 100%;

  .action {
    width: 100%;
  }

  @include breakpoint(md) {
    width: calc(50% - (15px / 2));
  }

  @include breakpoint(lg) {
    .action {
      width: 48%;
    }
  }
}

$statusVinculoList: (
  0: $cor4,
  1: $cor7,
  2: $cor13,
  3: $cor13,
);

@each $statusName, $color in $statusVinculoList {
  .status-vinculo-#{$statusName} {
    color: $color;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $color;
      margin-right: 10px;
    }
  }
}

.card-modulos {
  padding: 15px 5px;
  width: 100%;
  background-color: #FAFAFA;
  border: 1px solid #E1E1E1;

  .action {
    width: 100%;
    height: max-content;
  }

}

.card-indicador {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid $cor10 !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  

  &.cor10 {
    color: $cor10;
    border-color: $cor10;

    .indicador::after {
      background-color: $cor10;
    }

    .indicador-icon {
      background-color: $cor10;
    }

    &.active, &:hover {
      background-color: $cor10;
    }
  }

  &.cor4 {
    color: $cor4;
    border-color: $cor4 !important;

    .indicador::after {
      background-color: $cor4;
    }

    .indicador-icon {
      background-color: $cor4;
    }

    &.active, &:hover {
      background-color: $cor4;
    }
  }

  &.cor7 {
    color: $cor7;
    border-color: $cor7 !important;

    .indicador::after {
      background-color: $cor7;
    }

    .indicador-icon {
      background-color: $cor7;
    }

    &.active, &:hover {
      background-color: $cor7;
    }
  }

  &.cor8 {
    color: $cor8;
    border-color: $cor8 !important;

    .indicador::after {
      background-color: $cor8;
    }

    .indicador-icon {
      background-color: $cor8;
    }

    &.active, &:hover {
      background-color: $cor8;
    }
  }

  &.cor23 {
    color: $cor23;
    border-color: $cor23 !important;

    .indicador::after {
      background-color: $cor23;
    }

    .indicador-icon {
      background-color: $cor23;
    }

    &.active, &:hover {
      background-color: $cor23;
    }
  }

  &.active, &:hover {
    color: white;

    .indicador-icon {
      background-color: white;

      img {
        filter: none;
      }
    }

    .indicador::after {
      background-color: white;
    }
  }

  .card-body {
    padding: 1rem !important;
  }

  .indicador-icon {
    width: 42px;
    height: 42px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      filter: brightness(0) invert(1);
    }
  }

  .indicador {
    position: relative;
    margin-bottom: 15px;
    text-align: right;
    
    .valor-indicador {
      font-size: 2.5rem;
      text-align: right;
      letter-spacing: -0.03em;
      font-weight: 600;
      line-height: 1;
    }

    .porcentagem-indicador {
      font-size: 30px;
      font-weight: normal;
    }

    &::after {
      content: "";
      position: absolute;
      background-color: white;
      width: 80px;
      height: 3px;
      right: 0px;
      bottom: -6px;
    }
  }
  .legend {
    font-weight: 600;
  }

  h5 {
    font-size: 1.625rem;
  }
}

.card-legenda {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid $cor13 !important;
  width: max-content;
  cursor: default;
 

  &.cor13 {
    color: $cor13;
    border-color: $cor13;

    .indicador::after {
      background-color: $cor13;
    }

    .indicador-icon {
      background-color: $cor13;
    }
  }


  .card-body {
    padding: 1rem !important;
    /* width: 317px;*/
    height: 100%; 
    border-radius: 5px;

  }

  .indicador-icon {
    width: 42px;
    height: 42px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      filter: brightness(0) invert(1);
    }
  }

  .indicador {
    position: relative;
    margin-bottom: 25px;
    text-align: right;
    
    .valor-indicador {
      font-size: 16px;
      text-align: left;
      font-weight: 600;
      line-height: 19px;
    }

    .porcentagem-indicador {
      font-size: 60px;
      font-weight: normal;
    }

    &::after {
      content: "";
      position: absolute;
      background-color: white;
      width: 67px;
      height: 2px;
      right: 0px;
      bottom: -6px;
      border-radius: 5px;
    }
  }
  .legend {
    font-weight: 400;
    font-size: 15px;
    color: $corSecundaria;
    line-height: 19px;
  }

  #circle-cor4 {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: $cor4;
    margin-right: 9px;
  }

  #circle-cor12 {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #F93131;
    margin-right: 9px;
  }

  #circle-cor7 {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #4EC33B;
    margin-right: 9px;
  }
  #text-legend {

    margin-bottom: 5px;
  }
  }