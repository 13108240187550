.connect-histories .historico-atendimento{
    transform-style: preserve-3d;
    position: relative;

    &:not(:last-child)::after{
        content: "";
        position: absolute;
        top: 50%;
        left: 1rem;
        width: 2px;
        height: 100%;
        background-color: #B0D7EC;
        transform: translateZ(-1px);
    }
}

.historico-atendimento {
    background: #FFFFFF;
    border: 1px solid #B0D7EC;
    margin-top: 16px;
    padding: 0px 15px 22px 26px;

    .subnav-perfilpessoa .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: $cor8;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link {
        border-color: #C4C4C4;
    }

    .a .nav-tabs .nav-link:hover {
        border-color: #C4C4C4;
        color: $cor10;
    }

    .table-border-top-0 {
        border-top: none !important;
    }

}

.nav-item {

    white-space: nowrap;
}

.subnav-perfilpessoa {

    .nav-link {
        border-top: none;
        border-right: none;
        border-left: none;
        color: $cor13;
        padding-bottom: 20px;
    }

    .nav-link.active {
        position: relative;
        color: $cor8 !important;
        border-bottom-color: #dee2e6;
        background-color: transparent;
        border-color: transparent;

        img {
            filter: invert(46%) sepia(7%) saturate(3531%) hue-rotate(242deg) brightness(99%) contrast(83%);
        }

        &:hover {
            border-bottom-color: #dee2e6;
        }

        &:after {
            position: absolute;
            height: 5px;
            width: 60px;
            border-radius: 5px;
            background-color: $cor8;
            content: ' ';
            bottom: 0px;
            left: 0;
            transform: translateY(50%);
        }
    }

    .nav-link:focus,
    .nav-link:hover {
        border-color: transparent;
    }

    &.exames-subnav {
        a {
            font-weight: bold;
            font-size: 1.125rem;
        }
    }
}

.nav-perfil-pessoa {

    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: -15px;
    margin-right: -15px;
    height: 100%;
    line-height: 1;
    padding-bottom: 10px;
    border: none;

    .nav-item {
        width: calc(100% / 3.5);
        min-width: calc(100% / 3.5);
        border: 1px solid #D3D3D3;

        &.monitoramento-tab {
            min-width: auto;
            width: max-content;
        }
    }

    .nav-link {
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0;
        color: $cor13;
        flex-direction: column;
        text-align: center;
        white-space: normal;
        font-size: .875rem;
        height: 100%;

        img {
            margin-bottom: 5px;
        }
    }

    @include breakpoint(md) {
        flex-wrap: wrap;
        overflow: hidden;
        margin-left: 0;
        margin-right: 0;
        padding-bottom: 0;
        border-bottom: 1px solid #dee2e6;

        .nav-item {
            width: max-content;
            min-width: auto;
            border: none;
        }

        .nav-link {
            flex-direction: row;
            white-space: nowrap;
            font-size: 1rem;
            padding-bottom: 20px;

            img {
                margin-bottom: 0;
            }

            &.active {
                color: $cor8 !important;
                border-bottom-color: #dee2e6;
                background-color: transparent !important;
                border-color: transparent;

                img {
                    filter: invert(46%) sepia(7%) saturate(3531%) hue-rotate(242deg) brightness(99%) contrast(83%) !important;
                }

                &:hover {
                    border-bottom-color: #dee2e6;
                }

                &:after {
                    position: absolute;
                    height: 5px;
                    width: 60px;
                    border-radius: 5px;
                    background-color: $cor8;
                    content: ' ';
                    bottom: 0px;
                    left: 0;
                    transform: translateY(50%);
                }
            }
        }
    }


    .nav-link.active {
        position: relative;
        color: white;
        border-color: #dee2e6;
        background-color: $cor8;

        img {
            filter: brightness(0) invert(1);
        }
    }

}

.nav-detalhes-vinculo {
    @extend .subnav-perfilpessoa;

    .nav-item {
        width: calc(100% / 5);
        min-width: 120px;
        text-align: center;

    }

    .nav-link {
        padding-bottom: 10px;
    }

    .nav-link.active:after {
        width: 100%;
    }
}

.dropdown-tabs {
    img {
        filter: invert(46%) sepia(7%) saturate(3531%) hue-rotate(242deg) brightness(99%) contrast(83%) !important;
    }
}

#exames-subtabs {
    flex-wrap: nowrap;

    li {
        white-space: normal;
        height: 100%;
    }
}


.disable-old-case {
    color: white !important;
    background-color: #BDBDBD !important;
    pointer-events: none !important;
    outline: 1px solid #BDBDBD !important;
    border: 1px solid #BDBDBD !important;
    filter: grayscale(100%);
}

.disable-old-case-outline,
.btn-color-8.outline.disable-old-case-outline {
    color: #BDBDBD !important;
    outline: 1px solid #BDBDBD !important;
    border: 1px solid #BDBDBD !important;
    background-color: white !important;
    pointer-events: none !important;
    filter: grayscale(100%);
}