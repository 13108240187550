.gestao-paciente {

    #form-buscar {
        margin: 0;

        .rotate-top {
            transform: rotate(-180deg);
            transition: transform 0.5s;
        }

        .rotate-bottom {
            transform: rotate(0deg);
            transition: transform 0.5s;
        }

        #filtros {
            overflow: hidden;
            max-height: 0;
            transition: max-height .5s ease-in-out; 
        }

        #buscar-topo {
            transition: opacity 0.5s;
        }

        &.active {
            #filtros{
                max-height: 3000px;
            }
        }
    }
        
    .filtros {
        display: none;
    }

    .search-error {
        ul  {
            list-style: none;
            text-decoration: none;
            font-size: .875rem;
            margin: 0;
        }
    }

    @include breakpoint(md) {
        .gestao-caso-wrapper {
            background-color: white;
            border-radius: 5px;
            box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
            padding: 24px 32px;
        }
    }
}