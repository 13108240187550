.error-container {
    word-break: break-word;
    .error-ilustracao {
        width: 100%;
        max-width: 528px;
    }
    .error-codigo {
        font-size: 3rem;
        text-align: center;
        @media only screen and (min-width: 768px) {
            font-size: 4.5rem;
            text-align: left;
        }
    }
    .info-container {
        @media only screen and (max-width: 576px) {
            padding: 0 !important;
        }
    }
}
