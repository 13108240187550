.pre-natal-inicio {
    .landingpage__bg {
        background-repeat: no-repeat;
        background-image: url("../../static/images/icones-pre-natal/bg-landing-page.svg");
        background-size: cover;
        background-position: center;
    }
    .font-weight-800 {
        font-weight: 800;
    }
    .btn-login {
        border-width: 3px !important;
        height: 58px;
        font-weight: 600;
    }
    .card-secao {
        text-align: right;
        overflow: hidden;
        height: 120px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }
    .title,
    .title-color8 {
        color: $cor4;
        position: relative;
        font-size: 26px;
        @include breakpoint(md) {
            font-size: 3.5rem;
        }
        &::before {
            content: "";
            display: block;
            width: 167px;
            height: 3px;
            background-color: $cor3;
            position: absolute;
            bottom: -13px;
        }
        >span {
            color: $cor3;
            font-weight: bold;
        }
    }
    .title-color8 {
        color: $cor8;
        &::before {
            background-color: $cor8;
        }
    }
    .beneficios-card {
        height: 242px;
        padding: 0;
        border-radius: 5px;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &.active {
            border: none !important;
        }
        &.gestante {
            background-image: url("../../static/images/icones-pre-natal/bg-gestante-section.png");
        }
        &.gestor {
            background-image: url("../../static/images/icones-pre-natal/bg-gestor-section.png");
        }
        &.profissional {
            background-image: url("../../static/images/icones-pre-natal/bg-profissional-section.png");
        }
        .overlay {
            height: 100%;
            width: 100%;
        }
        .overlay {
            background-color: rgba($cor8, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.active>.overlay {
            background-color: rgba($cor10, 0.8);
        }
        .beneficios-text {
            opacity: 1;
            font-size: 26px;
            font-weight: 700;
            color: white;
            margin-bottom: 0;
        }
    }
    .beneficio-card {
        background-color: #fcfcfc;
        height: 185px !important;
    }
}

#modalBoasVindasProfissional,
#modalBoasVindasGestante {
    .modal-content {
        min-height: 645px !important;
    }
    .modal-dialog {
        max-width: 933px !important;
    }
    button.close {
        background-color: transparent;
        padding: 0;
    }
    .modal-header {
        background-color: #3e7caa !important;
        h5 {
            font-family: "Noto Sans", sans-serif;
            color: white;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
        }
    }
    .modal-body {
        button {
            width: 123.47px !important;
        }
        background-image: url("../../static/images/icones-pre-natal/bg-boas-vindas.svg");
        background-repeat: no-repeat;
        .boas-vindas-content {
            transition: transform 0.3s ease-out;
        }
        .etapa-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: -0.03em;
            color: $cor3;
        }
        .etapa-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.03em;
            color: $cor10;
        }
        .modal-acoes {
            padding-top: 1.5rem;
            border-top: 1px solid $cor2;
        }
        .nav-container {
            width: 292px;
        }
        .progress-modal {
            .etapa {
                border-radius: 100%;
                width: 6.93px;
                height: 6.93px;
                border: 1px solid $cor10;
                &.atual {
                    background-color: $cor10;
                }
            }
        }
    }
}