
.email-card {
    border: 1px solid $cor10;
}

.email-header {
    padding: 20px;
    background-color: $cor10;
    text-align: center;
}

.email-body {

    strong {
        color: $cor10;
    }
}

.email-body {
    padding: 20px;
    background-color: #E4EFF5;
    text-align: center;
}