.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.mt-section {
  margin-top: 5.5rem;

  @media (max-width: 780px) {
    margin-top: 7.5rem;
  }
}