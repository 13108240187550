.acoes{
    border: 1px solid #A664B8; 
    border-radius: 4px; 
    padding: 4px; 
    cursor: pointer; 
    background-color: transparent;
}

#tabela-resultados {
    .dropdown-menu {
        outline: 0;
        border: 1px solid $cor8;
        border-radius: 0.25rem;
        .dropdown-item {
            padding: 0.5rem 1rem;
            color: $cor8 !important;
            font-weight: 400;
            a{
                color: $cor8;
            }
            &:hover{
                background-color: $cor8;
                color: white;
                a{
                    color: white;
                }
            }
        }
    }
}

.filter{
    cursor: pointer;
}