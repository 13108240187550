
span.break {
  word-wrap: break-word;
  text-align: left;
  line-height: 17px;

  &::before {
    content: "";
    display: block;
    width: 48px;
    height: 1px;
    background-color: $cor10;
    position: absolute;
    bottom: 12px; 
  }
}



