.buscar-vinculo {
    .hv {
        width: 1px;
        background: #a6a6a6;
    }

    .date-input {
        display: flex;
        flex-direction: row;
        width: max-content;
        height: max-content;

        input {
            border-right: none;
            border-radius: 5px 0 0 5px;
            max-width: 178px !important;
            width: 100%;
            font-variant-numeric: tabular-nums; 
        }

        .input-group-text {
            border: 2px solid $cor10;
            background-color: $cor10;
        }
    }

    #id_id {
        margin-bottom: 10px;

        @include breakpoint(lg) {
            margin-bottom: 0;
        }
    }

    .filtros-vinculos {
        max-height: 0; 
        transition: max-height 0.5s ease-out; 
        overflow: hidden;

        &.active {
            max-height: 600px; 
        }
    }
}