.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.txt-btn-700 {
  font-weight: bold;
  font-size: 1.25rem;
  border-radius: 5px;
}

.max-btn-size-login {
  max-width: 375px;
}

.max-btn-size-sala-situacao {
  max-width: 319px !important;
  max-height: 50px;
}


.btn-enter-sabia {
  color: white;
  @extend .txt-btn-700;
  @extend .max-btn-size-login;
  background-color: $cor3;
  border: 2px solid $cor3;

  &:hover {
    background-color: $cor10;
    border-color: $cor10;
    color: white;
  }

  &:focus {
    background-color: $cor10;
    color: white;
  }



  .btn-enter-sabia-text-desktop {
    display: none;
  }

}

.btn-enter-sabia-small {
  @extend .btn-enter-sabia;

  font-size: 1rem;

  img {
    max-width: 30px;
    margin-right: 10px;
  }

  @include breakpoint(md) {
    font-size: 1.25rem;

    .btn-enter-sabia-text-desktop {
      display: block;
    }

    .btn-enter-sabia-text-mobile {
      display: none;
    }

    img {
      max-width: 40px;
    }
  }
}

.btn-outline-create-account {
  border: 2px solid $cor3;
  color: $cor3;
  @extend .txt-btn-700;
  @extend .max-btn-size-login;

  &:hover {
    background-color: white;
    color: $cor3;
    border: 2px solid $cor3;
  }

  &:focus {
    background-color: white;
    color: $cor3;
    border: 2px solid $cor3;
  }

}


.btn-more-sabia {
  @extend .txt-btn-700;
  @extend .max-btn-size-login;
  background-color: transparent;
  color: $cor3;
  font-size: 1rem;

  &:hover {
    background-color: white !important;
    color: $cor3 !important;
    border: none !important;
  }

  &:focus {
    background-color: white;
    color: $cor3;
    border: none;
  }

  @include breakpoint(md) {
    font-size: 1.25rem;
  }

}


.btn-adicionar-vinculo {
  color: $cor3;
  border-color: $cor3;
  font-size: 1rem;

  &:hover {
    background-color: $cor3;
    border-color: $cor3;
    color: white;

    .icone-adicionar-vinculo {
      filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
      -webkit-filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
      -moz-filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
    }
  }
}

.btn-acessar-modulos {
  background-color: $cor6;
  border: 1px solid $cor3;
  color: $cor3;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 600;

  &:hover {
    background-color: $cor3;
    color: white;

    img {
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
      -moz-filter: brightness(0) invert(1);
    }
  }
}

.btn-reativar-modulos {
  background-color: $cor6;
  border: 1px solid $cor9;
  color: $cor9;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 600;

  &:hover {
    background-color: $cor9;
    color: white;

    img {
      filter: brightness(0) invert(1);
      -webkit-filter: brightness(0) invert(1);
      -moz-filter: brightness(0) invert(1);
    }
  }
}

.btn-modal-cancelar {
  border: 3px solid $cor10;
  color: $cor10;
  border-radius: 5px;
  font-weight: 600;
  height: 3rem;
  background: none;

  &:hover {
    background-color: white;
    border-color: $cor10;
    color: $cor10;
  }
}

.btn-modal-proximo {
  background: $cor10;
  font-weight: 600;
  color: white;
  border: 1px solid $cor10;
  border-radius: 5px;
  // height: 3rem;

  &:disabled {
    background-color: $cor9;
    border-color: $cor9;
  }

  &:hover {
    color: white;
  }
}

.btn-modal-fechar {
  background: $cor10;
  font-weight: 600;
  color: white;
  border: 1px solid $cor10;
  border-radius: 5px;
  height: 3rem;

  &:hover {
    color: white;
  }
}

.btn-modal-proximo.disabled {
  cursor: default;

  &:hover {
    color: white;
  }
}

.btn-modal-voltar {
  border: 1px solid $cor10;
  color: $cor10;
  border-radius: 5px;
  height: 3rem;
  background: none;

  &:hover {
    border-color: $cor10;
    color: $cor10;
  }
}

.btn-modal-modulo {
  border: 3px solid $cor3;
  background-color: transparent;
  color: $cor3;
  width: 100%;
  height: 3rem;
  border-radius: 5px;

  &.cancelar-modulo {
    position: relative;
    cursor: pointer;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  &:disabled {
    cursor: default;
    opacity: .65;
  }
}

.btn-modal-modulo.active {
  border: 3px solid $cor3;
  background-color: $cor3;
  color: white;
  width: 100%;
  height: 3rem;
  border-radius: 5px;
}

.btn-alternar-vinculo {
  color: $cor10;
  font-weight: 600;
  border-color: $cor2;
  font-size: 0.8rem;
  display: inline-block;

  &:hover {
    color: white;
    border-color: $cor10;
    background-color: $cor10;
  }

  .seta-dropdown {
    fill: $cor10;
  }

  &.active {
    background-color: $cor10;
    border-color: $cor10;
    color: white;
  }

}

.btn-text {
  background: transparent;
  border: none;

  img {
    transition: all 0.3s ease-in-out;
  }

  &.active {
    img {
      transform: rotate(180deg);
    }
  }
}

.btn-dashboard {
  border-radius: 5px;
  font-size: 1rem;
  padding: 5px 10px;
  font-weight: 600;

  img {
    filter: brightness(0) invert(1);
  }

  &:disabled {
    cursor: default;
    opacity: .65;
  }

  &.outline {

    img {
      filter: none;
    }

    &:hover {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.btn-color-3 {
  @extend .btn-dashboard;
  background-color: $cor3;
  border: 1px solid $cor3;
  color: white !important;
  font-weight: 400;
  text-align: center;

  &.outline {
    background-color: transparent;
    color: $cor3 !important;
  }

  &:hover,
  &.active,
  &:focus {
    background-color: $cor3;
    color: white !important;
    border-color: $cor3;
  }
}

.btn-color-2 {
  @extend .btn-dashboard;
  background-color: $cor2;
  border: 1px solid $cor2;
  color: white !important;
  font-weight: 400;
  text-align: center;

  &.outline {
    background-color: transparent;
    color: $cor10 !important;
    font-weight: bold;

    &:hover,
    &.active,
    &:focus {
      background-color: $cor10 !important;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &:hover,
  &.active,
  &:focus {
    background-color: $cor2;
    color: white !important;
    border-color: $cor2;
  }
}

.btn-color-8 {
  @extend .btn-dashboard;
  background-color: $cor8;
  border: 1px solid $cor8;
  color: white !important;
  font-weight: 400;

  &.outline {
    background-color: transparent;
    color: $cor8 !important;

    &.selecionar-plano{
      &:hover{
        background-color: rgba(166, 100, 184, 0.2);
        color: $cor8 !important;
      }
      &.active{
        background-color: #33B955;
        color: white !important;
        border: none;
      }

    }

    &.no-hover:hover {
      background-color: transparent;
      color: $cor8 !important;
      border-color: $cor8;
    }
  }

  &:hover,
  &.active,
  &:focus {
    background-color: $cor8-hover;
    color: white !important;
    border-color: $cor8-hover;
  }

  &:disabled {
    background-color: white;
    color: $cor8 !important;
    border-color: $cor8;
  }
}


.btn-busca {
  @extend .btn-dashboard;
  background-color: $cor8;
  border: 1px solid $cor8;
  border-radius: 5px;
  height: 35px;
  width: calc(100% - 30px);
  color: white;
  font-weight: 400;


  &.outline {
    background-color: $cor8;
    color: white;
  }

  &:hover {
    background-color: white;
    color: $cor8;
  }

  @include breakpoint(md) {
    width: 172px;
  }

}

.btn-busca-plano {
  @extend .btn-dashboard;
  background-color: white;
  border: 1px solid $cor8;
  border-radius: 5px;
  height: 35px;
  width: calc(100% - 30px);
  color: $cor8;
  font-weight: 400;

  &:hover {
    background-color: $cor8;
    color: white;
  }

  @include breakpoint(md) {
    width: 172px;
  }

}

.btn-busca-diario {
  @extend .btn-dashboard;
  background-color: white;
  border: 1px solid $cor10;
  border-radius: 5px;
  height: 35px;
  width: 172px;
  color: $cor10;
  font-weight: 400;

  &:hover {
    background-color: $cor10;
    color: white;
  }

}

.btn-dose-aplicada {
  @extend .btn-dashboard;
  background-color: white;
  border: 2px solid $cor7;
  border-radius: 50px;
  color: $cor7;
  font-weight: 400;
  &.fora-prazo {
    border: 2px solid #F93131;
    color: #F93131;
    &:hover {
      background-color: white;
      color: #F93131;
    }
  }

  &.outline {
    background-color: transparent;
    color: $cor7;
    
  }

  &:hover {
    background-color: white;
    color: $cor7;
  }
}

.btn-outline-primary-custom {
  border-color: $cor10;
  color: $cor10;
  width: 100%;
  display: block;

  @include breakpoint(md) {
    width: auto;
    display: inline-block;
  }
}

.btn-dose-planejada {
  @extend .btn-dashboard;
  background-color: white;
  border: 2px solid #797979;
  border-radius: 50px;
  color: #797979;
  font-weight: 400;

  &.outline {
    background-color: transparent;
    color: #797979;
  }

  &:hover {
    background-color: white;
    color: #797979;
  }
}

.btn-pendente {
  @extend .btn-dashboard;
  background-color: white;
  border: 2px solid $cor3;
  border-radius: 50px;
  color: $cor3;
  font-weight: 400;

  &.outline {
    background-color: transparent;
    color: $cor3;
  }

  &:hover {
    background-color: white;
    color: $cor3;
  }
}

.btn-falha {
  @extend .btn-dashboard;
  background-color: white;
  border: 2px solid $cor12;
  border-radius: 50px;
  color: $cor12;
  font-weight: 400;

  &.outline {
    background-color: transparent;
    color: $cor12;
  }

  &:hover {
    background-color: white;
    color: $cor12;
  }
}

.btn-color-10 {
  @extend .btn-dashboard;
  background-color: $cor10;
  color: white;
  border: 1px solid $cor10;
  height: 2.8rem;

  &.outline {
    background-color: transparent;
    color: $cor10;

    img {
      filter: none;
    }

    &.no-hover:hover {
      background-color: transparent;
      color: $cor10 !important;
      border-color: $cor10;
    }

    &:hover {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.btn-buscar {
    height: auto;
  }

  &:hover {
    background-color: $cor10;
    color: white;
  }

  &.active {
    background-color: $cor10;
    color: white;
  }

  img {
    filter: brightness(0) invert(1);
  }

}

.btn-color-7 {
  @extend .btn-dashboard;
  background-color: $cor7;
  color: white;
  border: 1px solid $cor7;
  height: 2.8rem;

  &.outline {
    background-color: transparent;
    color: $cor7;

    img {
      filter: none;
    }

    &.no-hover:hover {
      background-color: transparent;
      color: $cor7 !important;
      border-color: $cor7;
    }

    &:hover {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.btn-buscar {
    height: auto;
  }

  &:hover {
    background-color: $cor7;
    color: white;
  }

  &.active {
    background-color: $cor7;
    color: white;
  }

  img {
    filter: brightness(0) invert(1);
  }

}



.btn-color-10-outline {
  @extend .btn-dashboard;
  background-color: white;
  color: $cor10;
  border: 1px solid white;
}


.paginacao {
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;

  li {
    display: inline-block;

    a,
    button {
      border: none;
      background-color: transparent;
      font-variant-numeric: tabular-nums;
      color: #4B4B4B;
    }

    &.page {

      a,
      button {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin: 5px 2px;
        display: flex;
        justify-content: center;
      }
    }

    &:not(.page) {

      a,
      button {
        margin: 5px 8px;
      }
    }

    &.disabled {

      a,
      button {
        color: $corSecundaria;
        cursor: default;
      }
    }

    &.active {

      a,
      button {
        color: white;
        background-color: $cor10;
      }
    }

  }
}


.btn-ver-dados-completos,
.btn-editar-plano-terapeutico,
.btn-editar-exame,
.btn-editar-observacao,
.btn-editar-historico,
.btn-relatorio-gerencial {
  color: $cor10;
  border-color: $cor10;
  font-size: 1rem;
  background-color: #FFFFFF;

  &:hover {
    background-color: $cor10;
    border-color: $cor10;
    color: white;

    .icone-adicionar-vinculo,
    span.break::before {
      filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
      -webkit-filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
      -moz-filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
    }
  }
}



.btn-fila-emissao {
  color: $cor10;
  border-color: $cor2;
  font-size: 1rem;

  &:hover {
    background-color: $cor10;
    border-color: $cor10;
    color: white;

    .icone-adicionar-vinculo {
      filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
      -webkit-filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
      -moz-filter: grayscale(100%) brightness(200%) saturate(0%) contrast(1000%);
    }
  }
}

.btn-central-ajuda {
  color: $cor10;
  border-color: $cor10;
  font-size: 1rem;
  background-color: #FFFFFF;
  width: 301px;
  height: 51px;

  &:hover {
    background-color: $cor10;
    border-color: $cor10;
    color: white;
  }
}


.btn-status-busca {
  @extend .btn-dashboard;
  background-color: white;
  color: $cor3;
  position: absolute;
  width: 160px;
  height: 32px;
  border: 2px solid $cor3;
  box-sizing: border-box;
  border-radius: 23px;
  outline: none;
  font-weight: 500;
  padding: 0px 0px 0px 10px;

  &.outline {
    background-color: transparent;
    color: $cor3;

  }

  &:hover {
    background-color: white;
    color: $cor3;
  }
}

.btn-more-dados-epidemiologicos {
  @extend .txt-btn-700;
  background-color: transparent;
  color: $cor3;
  width: 100%;
  font-size: 1rem;

  &:hover {
    background-color: white !important;
    color: $cor3 !important;
    border: none !important;
  }

  &:focus {
    background-color: white;
    color: $cor3;
    border: none;
  }

  @include breakpoint(md) {
    width: max-content;
    font-size: 1.25rem;
  }

}

.check-button {
  border-radius: 50%;
  width: 28px !important;
  height: 28px !important;
  background-color: #A6A4A4;
  background-image: url("../images/icone-checked.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border: none;

  &.active {
    background-color: #1F8F3C;
  }
}


.btn-busca-ativa {
  background-color: #fff;
  border: 1px solid #D73551;
  border-radius: 5px;
  height: 35px;
  width: 172px !important;
  width: calc(100% - 30px);
  color: #D73551;
  background-image: url('/static/images/icone-busca-ativa-danger.svg');
  background-repeat: no-repeat;
  background-position: 27px;
  padding-left: 30px;
}

.btn-lancar-sinan {
  background-color: #fff;
  border: 1px solid #33B955;
  border-radius: 5px;
  height: 35px;
  min-width: 192px;
  color: #33B955;

  &:hover {
    background-color: #33B955;
    color: #fff;
  }
}

.btn-card-disabled {
  @extend .btn-dashboard;
  background-color: #A6A6A6;
  color: white;
  border: 1px solid #A6A6A6;
  height: 1.5rem;

  &.outline {
    background-color: transparent;
    color: #A6A6A6;

    img {
      filter: none;
    }

    &.no-hover:hover {
      background-color: transparent;
      color: #A6A6A6 !important;
      border-color: #A6A6A6;
    }
  }

  &:hover {
    background-color: $cor10;
    border-color: $cor10;
    color: white;
  }

}

.btn-periodo,
.btn-filtros {
  @extend .btn-dashboard;
  background-color: $cor8;
  border: 1px solid $cor8;
  color: white !important;
  font-weight: 400;

  &.outline {
    background-color: transparent;
    color: $cor8 !important;

    &.no-hover:hover {
      background-color: transparent;
      color: $cor8 !important;
      border-color: $cor8;
    }
  }

  &:hover,
  &.active,
  &:focus {
    background-color: white;
    color: $cor8 !important;
    border-color: $cor8;
  }

  &:disabled {
    background-color: white;
    color: $cor8 !important;
    border-color: $cor8;
  }
}

.btn-color-5 {
  @extend .btn-dashboard;
  background-color: $cor5;
  color: white;
  border: 2px solid $cor5;
  height: 2.8rem;

  &.outline {
    background-color: transparent;
    color: $cor5;

    img {
      filter: none;
    }

    &.no-hover:hover {
      background-color: transparent;
      color: $cor5 !important;
      border-color: $cor5;
    }

    &:hover {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &.btn-buscar {
    height: auto;
  }

  &:hover {
    background-color: $cor5;
    color: white;
  }

  &.active {
    background-color: $cor5;
    color: white;
  }

  img {
    filter: brightness(0) invert(1);
  }

}

.btn-color-5-outline {
  @extend .btn-dashboard;
  background-color: white;
  color: $cor5;
  border: 1px solid white;
}