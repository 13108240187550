.switch-container {
    .switch {
        position: relative;
        display: inline-block;
        width: 100px;
        height: 30px;
    }
    .switch input {
        display: none;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #8B1319;
        -webkit-transition: .4s;
        transition: .4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 30px;
        width: 30px;
        left: -1px;
        bottom: 0px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    input:checked+.slider {
        background-color: #0E522A;
    }
    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }
    input:checked+.slider:before {
        -webkit-transform: translateX(72px);
        -ms-transform: translateX(72px);
        transform: translateX(72px);
        background-color: #178545;
    }
    input+.slider:before {
        background-color: #C74447;
    }
    .on {
        display: none;
    }
    .on,
    .off {
        color: white;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    input:checked+.slider .on {
        display: block;
    }
    input:checked+.slider .off {
        display: none;
    }
    .slider.round {
        border-radius: 34px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
}