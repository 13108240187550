.subheader {
    background-color: white;
    padding: 0px;
    margin: 0;
    font-size: 0.875rem;
    align-items: center;
    position: relative;
    max-height: 6px;
    transition: all 0.5s ease-out;

    .container {
        opacity: 0;
        transition: opacity 0.6s ease-out;
    }

    .drop-subheader {
        position: absolute;
        top: 0;
        width: 24px;
        height: 24px;
        filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.15));
        background-color: white;
        border: none;
        border-radius: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        img {
            transition: all 0.5s ease-out;
        }
    }

    &.active {
        max-height: 100px;
        padding: 8px 0px; 

        .container {
            opacity: 1;
        }

        .drop-subheader {
            img {
                transform: rotate(180deg);;
            }
        }
    }

    @include breakpoint(md) {
        .drop-subheader {
            display: none;
        }
    }

    @include breakpoint(xl) {
        .first-col {
            border-right: 1px solid $cor10;
        }
    }
    
}