.dropdown-acesso-rapido {
    min-width: 240px;
    margin-top: 0.6rem;
    .titulo {
        text-align: center;
        padding: .5rem 0;
        font-size: 1.625rem;
        font-weight: 600;
        line-height: 120%;
        color: $cor10;
        border-bottom: 1px solid $cor24;
    }
    .modulo-selecao {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0.25rem;
        width: 6rem;
        padding: .5rem .25rem;
        margin-bottom: .5rem;
        &.selected {
            background: #D8E5EE;
        }
        &:hover {
            background: #D8E5EE;
        }
        gap: .5rem;
        img {
            width: 3rem;
            height: 3rem;
        }
        p {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 120%;
            color: $cor10;
            margin-bottom: .5rem;
        }
        .novo-modulo {
            color: white;
            display: flex;
            padding: 6px 8px;
            justify-content: flex-end;
            align-items: center;
            border-radius: 8rem;
            background: $cor7;
        }
    }

}