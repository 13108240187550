header {
  background-color: $cor2;

  .logo-custom {
    max-width: 70px;
    @include breakpoint(md) {
      max-width: 110px;
    }
  }

  .dropdown-toggle-custom-perfil {
    img {
      border-radius: 50%;
    }
  }

  .dropdown-item-custom {
    &:hover {
      background-color: $cor3;
      color: white;
      img {
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
        -moz-filter: brightness(0) invert(1);
      }
    }
  }

  .opcoes {
    gap: 20px;
    @include breakpoint(md) {
      gap: 3rem;
    }
    a {
      color: $cor5;
      font-size: 14px;
    }
  }

}

