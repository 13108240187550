#btn-legenda {
  background-color: white;
  border-color: $cor8;
  color: $cor13 !important;

  &:hover, &:focus {
    text-decoration: none;
    color: white;
  }

}



.border-bottom-color-7 {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background-color: $cor7;
    width: 40px;
    height: 3px;
    left: 0px;
    bottom: -10px;
  }
}

.border-bottom-color-10-2 {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background-color: $cor10;
    width: 40px;
    height: 3px;
    left: 0px;
    bottom: -10px;
  }
}

.bdcrumb3 {
  display: flex;
  flex-direction: row;
  color: $cor10;
  width: max-content;
  &:hover {
      color: $cor10;
  }
}

.artigos-uteis {
  border: 1px solid #D4D4D4;
  border-radius: 50px;
}

.title-painel-decasos {
  color: $cor8;
  position: relative;
  font-size: 26px;
  @include breakpoint(md) {
    font-size: 2.25rem;
  }

  &::before {
    content: "";
    display: block;
    width: 110px;
    height: 3px;
    background-color: $cor8;
    position: absolute;
    bottom: -13px;
  }

  > span {
    color: $cor3;
    font-weight: bold;
  }
}

.dropdown-menu-filtros {
  border-color: $cor8;
  padding-top: 2px;
  padding-bottom: 2px;
  
  a {
    color: $cor8;
    &:hover, &:active {
      background-color: #ede0f1;
      color: $cor8;
    }
  }
  
}