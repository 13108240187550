.modal-custom-size-1 {
  max-width: 533px;
}
.modal-header-custom{
  background-color: $cor2;

  @include breakpoint(md) {
    padding-left: 34px;
  }

  .close {
    color: transparent;
    background-color: $corSecundaria;
    border-radius: 50%;
    padding: 1px 9px 7px 9.5px;
    margin: 0;
  }
}

.modal-dialog-bottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  min-height: calc(100% - 1rem);

  @include breakpoint(md) {
    min-height: none;
    display: block;
  }
}

#msform {
  text-align: center;
  position: relative;
}

#msform fieldset .form-card {
  box-sizing: border-box;
  position: relative;
  margin-bottom: 28px;
  margin-top: 28px;
}

#msform .form-card img {
  width: 50%;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative
}

#msform fieldset:not(:first-of-type) {
  display: none
}

#msform fieldset .form-card {
  text-align: left;
  color: #343434;
}


#msform .form-field {

  input, .form-control, .select2, .custom-select {
    border: 2px solid #A6A6A6;
  }

  .select2 {
    width: 100% !important;
  }

}


select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px
}

select.list-dt:focus {
  border-bottom: 2px solid skyblue
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative
}

.fs-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left
}

.modulo-option-row {
  display: flex;
  text-align: center;
  justify-content: center;
}

.favorito-modulo {
  background-color: transparent;
  border: none;
  outline: none;

  & > div {
    background-repeat: no-repeat;
    background-position: center;
    height: 20px;
  }

  &:hover {
    color: $cor5 !important;

    & > div {
      background-image: url("/static/images/icone-favoritos-azul.svg") !important;
    }
  }

}

.modulos-chips {
  display: flex;
  flex-direction: row;
  align-items: center;

  .modulos-chip { 
    background-color: $cor5;
    padding: 5px 15px;
    border-radius: 20px;
    margin-right: 10px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    .remove-chip {
      height: 12px;
      width: 12px;
      cursor: pointer;
      background: url('/static/images/icone-close.svg');;
    }

  }
}


.modal-salus1 {
  .modal-header {
    background:$cor2;
  }

  a {
    color: $cor3;
  }

  .close {
    border-radius: 50%;
    background: $corSecundaria;
    text-shadow: none;
    color: $cor2;
    opacity: 1;
    padding: 5px 12px 9px;
    margin: 0;
  }

  .modal-footer {
    border: none;
  }
}

.subtitulo-modal {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #3E86AB;
}

.conteudo-modal-diario {
  margin-left: 35px;
}

.hr-modal-diario {
  margin-left: 35px;
  margin-right: 35px;
}

.historico-modal-diario {

  font-size: 16px;
  background: #FFFFFF;
  border: 1px solid #B0D7EC;
  margin-top: 16px;
  padding: 0px 15px 22px 26px;
  width: 100%;
  font-style: normal;
  font-weight: normal;

  .table-border-top-0 {
    border-top: none !important;
  }

}

#modalAdicionarExame {
  #div_id_objetivo > div, #div_id_encerrar > div {
    display: flex;
    justify-content: space-between;
  }
}