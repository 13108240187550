#meuperfil {
    background-color: rgba(244, 244, 244, 1);
    position: relative;
    padding-bottom: 50px;
    height: 100%;
    #id_registro::-webkit-outer-spin-button,
    #id_registro::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    #id_registro {
      -moz-appearance: textfield;
    }
}