#dashboard {
    background-color: rgba(244, 244, 244, 1);
    position: relative;
    padding-bottom: 50px;
    height: 100%;
    margin-bottom: 60px;

    #dashboard-content {
        margin-left: 0px;
        width: 100%;

        @include breakpoint(md) {
            margin-left: 72px;
            width: calc(100% - 72px);
        }
    }

    .dashboard-page {
        width: 100%;

        @include breakpoint(xl) {
            width: 80%;
        }
    }

    .section-title {
        display: flex;
        flex-direction: row;
        font-size: 1.25;
        font-weight: 700;
        text-transform: uppercase;
        color: $cor11;
    }

    #numRegistros {
        border-color: #a6a6a6;
        width: 50px;
    }

    #resultados:empty {
        display: none;
    }

    #pagination-container:empty {
        display: none;
    }
}