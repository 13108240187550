.form-field {

    &.hide-label {
        label {
            visibility: hidden;
        }
    }

    label {
        font-weight: 600;
    }

    .custom-select {
        padding-right: 1.5rem !important;
        text-overflow: ellipsis;
    }

    input,
    .form-control,
    .select2,
    .custom-select {
        border: 2px solid $cor2;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        background-color: white;

        &:hover {
            border: 2px solid $cor10;
        }

        &:focus {
            border: 2px solid $cor10;
            outline: 0 none;
            box-shadow: none;
        }

        &.disabled {
            border: 2px solid #bdbdbd;
            pointer-events: none;
        }

        &.placeholder-color10::placeholder {
            color: $cor10;
        }

        transition: background-image 0.5s;
    }

    .select2-container--default.select2-container--disabled .select2-selection--single {
        background-color: transparent;
    }

    #div_id_realizou_pre_natal,
    #div_id_controle_de_cura,
    #div_id_avaliacao_clinica,
    #div_id_notificado_sinan,
    #div_id_teste_rapido_falta,
    #div_id_trabalha_fora,
    #div_id_beneficiario_programa_governo,
    #div_id_identificar_companheiro {
        >div {
            display: flex;
            flex-direction: row;
            gap: 6rem;
            font-weight: normal;

            .custom-control-label {
                font-weight: 400;
            }
        }

        label {
            margin-bottom: 1rem;
        }
    }

    #div_id_parceiro_presente,
    #div_id_parceiro_convocado,
    #div_id_movimento_fetal,
    #div_id_risco_habitual,
    #div_id_alto_risco,
    #div_id_gravidez_planejada,
    #div_id_status {
        >div {
            display: flex;
            flex-direction: row;
            gap: 4rem;
            font-weight: normal;

            .custom-control-label {
                font-weight: 400;
            }
        }

        label {
            margin-bottom: 1rem;
        }
    }

    #div_id_controle_de_cura {
        margin-bottom: 0;
    }

    #div_id_receber_email_sinan, #div_id_receber_email_alertas, #div_id_receber_email_notificacoes,  #div_id_sintomas, #div_id_justificativa {
        .custom-control-label {
            font-weight: 400;
        }
    }

    #div_id_sintomas, #div_id_justificativa {
        .custom-control.custom-checkbox {
            margin-bottom: 0.5rem !important;
        }
    }

    // Select 2 styles
    .select2 {
        padding: 0;
        padding: 3px;
    }

    .select2-container--default .select2-selection--single {
        background: transparent;
        border: none;
    }

    .select2-selection__rendered {
        line-height: 1.5;
    }

    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
        background-color: $cor10 !important;
        border-color: $cor10 !important;
    }

    .custom-checkbox .custom-control-input:checked:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $cor10;
    }

    .custom-checkbox .custom-control-input:focus~.custom-control-label::before {
        box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem $cor2;
    }

    .custom-checkbox .custom-control-input:active~.custom-control-label::before {
        background-color: $cor2 !important;
    }

    select.color-8 {
        border-color: $cor8;
        color: $cor8;

        &:hover {
            border: 2px solid $cor8;
        }

        &:focus {
            border-color: $cor8;
        }
    }



}

input.form-field {
    border: 2px solid $cor2;
    border-radius: 5px;
    padding: 5px;
    width: 100%;

    &:hover {
        border: 2px solid $cor10;
    }

    &:focus {
        border: 2px solid $cor10;
        outline: 0 none;
        box-shadow: none;
    }

    transition: background-image 0.5s;
}

.search-field {
    @extend .form-field;

    &.has-icon {
        input {
            background-image: url("../images/icone-search.svg");
            background-repeat: no-repeat;
            background-position: calc(100% - 6px) 50%;
            padding-right: 30px;
            transition: background-image 0.5s;
        }

        &.mobile-only {
            @include breakpoint(md) {
                input {
                    background-image: none;
                    padding-right: 5px;
                }
            }
        }
    }

    .form-group {
        margin: 0 !important;
    }

    input::-webkit-calendar-picker-indicator {
        display: none !important;
    }
}

.label-azul-escuro {
    label {
        font-weight: 600;
        color: $cor3;
    }
}

.label-color-10 {
    label {
        font-weight: 600;
        color: $cor10;
    }
}



.hover-cor-10 {
    &:hover {
        color: $cor10;
    }
}



.checkbox-container {
    li {
        list-style: none;
        margin-bottom: 20px;

        label {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            color: $cor5;
            font-size: 1rem;
            font-weight: 600;
        }
    }
}

.input-icon-outline-cor10 {
    .input-group-text {
        background-color: transparent;
        border-color: $cor10;
    }
}

.input-outline-cor10 {
    border-color: $cor10;
    border-right: none;
}

#modalAdicionarVinculo {
    .custom-checkbox {
        width: 26px;
        height: 26px;
        display: inline-block;
        position: relative;
        z-index: 1;
        top: 3px;
        background: url("/static/images/icone-checkbox-add.svg");
        background-color: $cor5;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 3px;
        transition: background 0.3s ease-out;
        cursor: pointer;
    }

    .custom-checkbox.selected {
        background: url("/static/images/icone-checkbox-remove.svg");
        background-repeat: no-repeat;
        background-position: center;
    }

    .custom-checkbox input[type="checkbox"] {
        margin: 0;
        position: absolute;
        z-index: 2;
        cursor: pointer;
        outline: none;
        opacity: 0;
    }
}

#div_id_foto {
    display: none;
}

.container-img-perfil {
    position: relative;
    cursor: pointer;
    overflow: hidden;

    #img-editar-perfil {
        width: 100%;
        height: 100%;
    }

    .selecionar-foto {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.6026785714285714) 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 1;
        bottom: 0;
        left: 0;
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 0.875rem;
        padding: 5px;
        transition: opacity ease-in-out 0.5s;

        img {
            filter: brightness(0) invert(1);
            margin-right: 5px;
        }

        @include breakpoint(md) {
            opacity: 0;
        }
    }

    &:hover {
        .selecionar-foto {
            opacity: 1;
        }
    }
}

.form-field #div_id_avaliacao_clinica>div {
    gap: 3rem !important;
}

.gestao-caso-wrapper {
    div[role="tablist"] .card {
        background: transparent;

        .card-header {
            background-color: transparent;
            border: none;
            padding: 0;
            margin-bottom: 15px;

            h5 a {
                color: $cor8;
                font-weight: bold;
                font-size: 1.25rem;

                &:hover {
                    color: $cor8-hover;
                }

                &:after {
                    content: "";
                    background-image: url("/static/images/icone-dropdown-rosa.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    display: inline-block;
                    transition: transform 0.3s ease-out;
                }

                &[aria-expanded="true"] {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .card-body {
            padding: 0;
            margin-bottom: 20px;
        }
    }
}


#div_id_notificado_sinan>div {
    gap: 4rem !important;

    @include breakpoint(md) {
        gap: 6rem;
    }
}

.classificacaoStatus {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.custom-file-label {
    &::after {
        content: "Selecione";
    }
}